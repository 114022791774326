export default {
    login: 'залогин',
    registration: 'регистрировать',
    order: 'Мой приказ',
    language: 'русский язык',
    home: 'Главная страница',
    babyToys: 'Ключевое слово.',
    aboutus: 'О компании',
    product: 'продукция',
    cart: 'Корзина',
    contactus: 'Свяжись Мы',
    comprehensiveInquiry: 'Integrated Query',
    advancedSearch: 'Расширенный поиск',
    productName: 'Наименование',
    priceRange: 'Диапазон цен',
    reset: 'сброс',
    search: 'поиск',
    classify: 'категорирование',
    categories: 'Классификация',
    close: 'коллапс',
    open: 'раскат',
    updateTime: 'Время обновления',
    startTime: 'Время начинать',
    endTime: 'Время окончания',
    confirm: 'признать',
    minimumPrice: 'Минимум',
    highestPrice: 'высшая цена',
    L: 'L',
    W: 'W',
    H: 'H',
    price: 'цена',
    itemNo: 'Номер склада',
    packing: 'упаковка',
    productSize: 'стандарт продуктов',
    packageSize: 'упаковка',
    cartonSize: 'OuterBoxLWH',
    OuterPacking: "Внешний  Загрузка",
    CBMAndCUFT: 'CBM/CUFT',
    CUFT:'CUFT',
    CBM: 'CBM',
    GWAndNW: 'G.W/N.W',
    mOQ: 'MOQ',
    productInformation: 'информация о продукте',
    relatedProducts: 'Соответствующий продукт',
    more: 'больш',
    clear: 'очист',
    inputPassword: 'Введите пароль',
    passwordLength: 'Длина пароля не может быть меньше 6 бит',
    inputUserName: 'Введите имя',
    inputUserAccount: 'Введите имя пользователя',
    inputVaildeCode: 'Введите код веридан',
    inputMobile: 'Пожалуйста, введите номер телефона',
    inputEmail: 'Пожалуйста, введите почтовый ящик',
    inputEmailRules: 'Почтовый ящик неправильный',
    password: 'пароль',
    username: 'личное имя',
    vaildecode: 'Код подтверждения',
    gender: 'пол',
    useraccount: 'Имя пользователя',
    man: 'мужчина',
    woman: 'женский',
    mobile: 'Номер телефона',
    email: 'почтовый',
    address: 'Свяжись с адресом',
    CodeError: 'Ошибка капча',
    resUserError: 'Имя пользователя уже существует',
    notreviewed: 'Номер счета не проверен',
    loginError: 'Номер счета не был проверен или введен пароль с ошибкой',
    loginSuccess: 'Посадка завершена',
    regSuccess: 'Регистрация прошла успешно',
    deleteSuccess: 'Удаление удалено',
    toregister: "Номера счетов еще нет?Иди зарегистрируйся",
    toLogin: 'Номер счета уже есть?На посадку',
    logout: 'выход',
    myShoppingCart: 'Моя тележка',
    ID: 'ID',
    picture: 'изображение',
    ItemNO: 'пронумерация',
    name: 'имя',
    total: 'думать',
    delete: 'Делеция',
    CTNS: 'CTNS',
    checkAll: 'выбрать',
    checkSelect: 'На этой странице Один ключ плюс покупка',
    page: 'лист',
    goTO: 'пойти',
    totalRecords: 'Полный набор',
    totalCTNS: 'Полная коробка',
    totalQuantity: 'Общее количество',
    totalPrice: 'общая цена',
    submit: 'представить',
    orderDetails: 'Подробности приказа',
    OrderInformation: 'Приказ',
    number: 'нечетное число',
    orderTime: 'Время заказа',
    linkman: 'Контакт',
    remark: 'примечание',
    viewDetails: 'Проверьте детали',
    companyContact: 'Контакт',
    companyTel: 'телефон',
    companyFax: 'Факс',
    companyMail: 'почтовый',
    companyAddress: 'адрес',
    productCenter: 'продукты',
    helpCenter: 'Центр помощи',
    member: 'Центр для пользователей',
    productdemand: 'спрос на продукцию',
    newproduct: 'Последний продукт',
    registerhelp: 'Зарегистрировать помощь',
    orderhelp: 'Помощь с заказом',
    newproducts: 'Новая презентация',
    highRec: 'рекомендации',
    viewmore: 'Проверь еще',
    videocenter: 'Видеоцентр',
    searchPicture: 'Рисунок обыщ',
    choosePicture: 'Выбери картинку',
    clickOrDrag: 'Нажмите/перетащите файл сюда',
    pictureCrop: 'Область обработки изображений',
    cancel: 'Отмена',
    confirm: 'Определение',
    pleaseLogin: 'Заходите',
    addCart: "Добавьте тележку",
    delCart: "Отмена заказа",
    viewPrice: 'Проверь цены',
    noData: 'Приходящее множество доказательств',
    systemTip: 'Внимание',
    deleteData: 'Удалена ли эта статья данных?',
    clearCartData: 'Вы опустошили все тележки?',
    orderSuccess: 'Порядок создан!',
    submitTip: 'Подтверждение приказа?',
    noLogin: 'Добавьте тележку перед тем, как сесть',
    notImage: 'Содержание не является фотографией',
    noSelect: 'Пожалуйста, выберите данные',
    hotSaleProduct: 'Горячие продукты',
    popularRecommendation: 'Вверх Рекомендуем',
    please_contact_us: 'Если у вас есть какие-то вопросы, не стесняйтесь отправлять их по факсу или звонить',
    ock_email: 'почтовый',
    contactUs: 'Свяжись с нами',
    hotLine: 'Горячая линия',
    companyAddres: 'адрес',
    workingHours: 'рабочее время',
    week: 'С понедельника по пятницу',
    companyInfo: 'Профиль компании',
    noName: 'Промежуточный перевод',
    serviceHotine: "24/7 Горячая служба",
    success: "Добавить успех",
    del: 'Удаление удалено',
    Disclaimer: "Все изображения и продукты на этом сайте принадлежат производителям, авторские права принадлежат производителям.",
    Technic: 'Техническая поддержка',
    Software: 'HongSheng Компания, занимающаяся разработкой программного обеспечения',
    coNuNum: "Номер компании",
    all: 'Все по категориям',
    userAddress: 'Адрес доставки',
    consignee: 'грузополучатель',
    addressDetail: 'Подробный адрес',
    addressinformation: 'Адрес доставки',
    defaultAddress: 'По умолчан',
    defaultSettings: 'Устанавливать умолчание',
    edit: 'Адрес редактора',
    add: 'Новый адрес',
    AddinganAddress: 'Новый адрес',
    Edittheaddress: 'Адрес редактора',
    Ok: 'держать',
    Address_Consignee: 'Введите, пожалуйста, получателя',
    Address_Mobile: 'Пожалуйста, введите номер телефона',
    Address_Address: 'Введите адрес',
    intro: 'The Fourth Construction Co.,Ltd.of China Electronics System Engineering (referred to as CEFOC) was founded in 1953 and belongs to CEC China Electronic Systems Engineering Corporation. It is a comprehensive engineering and technical service company focusing on engineering consultation, engineering design, engineering contracting and facility management in the fields of electronic information, biopharmaceutical, chemical environmental protection, data center, automobile, new energy and other high-tech manufacturing industries.',
    orderStatus: 'Статус заказа',
    sendDate: 'Дата поставки',
    expressNumber: 'Номер посылки',
    unshipped: 'Неотправка',
    shipped: 'Груз доставлен',
    Video_playback: 'Видеопроизводство',
    "KL.news": 'Динамика новостей',
    "KL.newsDetail": 'Подробности',
    "KL.attestation": 'сертифицирова'
}   
