<template>
  <div class="container">
    <div class="detail-box">
      <div class="detail-title">
        <div>{{$t('KL.CardTit.productDetails')}}</div>
        <div></div>
      </div>
      <div class="detail-info">
        <div class="pro-box">
          <div class="img-info">
            <div class="goods-img">
              <pic-zoom :bigSrc="activeImg || defaultImg" v-if="defaultImg"></pic-zoom>
            </div>
            <div class="swiper">
              <swiper :options="swiperOption" ref="mySwiper" class="swiper-box">
                <swiper-slide class="swiper-slide" v-for="(item, index) in proInfo.imgList" :key="index">
                  <div class="item-img">
                    <img :src="item.filePath" @click.stop="hoverImg(item)" @mouseenter="setImg(item)" class="swiper-img" />
                  </div>
                </swiper-slide>
              </swiper>
              <!-- 左右箭头 -->
              <div class="swiper-button-prev"></div>
              <div class="swiper-button-next"></div>
            </div>
          </div>
          <div class="pro-info">
            <h3 v-html="$hs( proInfo.pr_Na,proInfo.pr_En_Na) || ''"></h3>
            <dl>
              <dt>{{$t('ItemNO')}}：</dt>
              <dd>
                <span>{{ proInfo.co_Nu }}</span>
              </dd>
            </dl>
            <dl>
              <dt>{{ $t('productSize') }}：</dt>
              <dd>
                {{ proInfo.pr_Le || 0 }} x {{ proInfo.pr_Wi || 0 }} x {{ proInfo.pr_Hi || 0 }}
                <span class="tip">(cm)</span>
              </dd>
            </dl>
            <dl>
              <dt>{{ $t('packing') }}：</dt>
              <dd>{{ $t('language')=== 'English' ? proInfo.en_Pa || '' : proInfo.ch_Pa || '' }}</dd>
            </dl>
            <dl>
              <dt>{{ $t('packageSize') }}：</dt>
              <dd>
                {{ proInfo.in_Le || 0 }} x {{ proInfo.in_Wi || 0 }} x {{ proInfo.in_Hi || 0 }}
                <span class="tip">(cm)</span>
              </dd>
            </dl>
            <dl>
              <dt>{{$t('KL.OuterQTY')}}：</dt>
              <dd>
                {{ proInfo.ou_Lo || 0 }}
                <span class="tip">(pcs)</span>
              </dd>
            </dl>
            <dl>
              <dt>{{$t('KL.innerBoxes')}}：</dt>
              <dd>
                {{ proInfo.in_En || 0 }}
                <span class="tip"></span>
              </dd>
            </dl>

            <dl>
              <dt>{{$t('KL.CartonSize')}}：</dt>
              <dd>
                {{ proInfo.ou_Le || 0 }} x {{ proInfo.ou_Wi || 0 }} x {{ proInfo.ou_Hi || 0 }}
                <span class="tip">(cm)</span>
              </dd>
            </dl>
            <!-- 
            <dl>
              <dt>{{ $t('CBMAndCUFT') }}：</dt>
              <dd>
                {{ proInfo.bulk_Stere || 0.00 }} (cbm) / {{ proInfo.bulk_Feet || 0.00 }}
                <span class="tip">(cuft)</span>
              </dd>
            </dl>-->
            <dl>
              <dt>{{ $t('GWAndNW') }}：</dt>
              <dd>
                {{ proInfo.gr_We || 0 }} / {{ proInfo.ne_We || 0 }}
                <span class="tip">(kg)</span>
              </dd>
            </dl>
            <dl>
              <dt style="width:130px;">{{$t('KL.attestation')}}：</dt>
              <dd>
                <span>{{ proInfo.attestation }}</span>
              </dd>
            </dl>
            <dl>
              <dt style="min-width: 50px;max-width: 80px;">{{$t('KL.remark')}}：</dt>
              <dd style="flex:1">{{ $hs(proInfo.remark,proInfo.en_Remark) || "" }}</dd>
            </dl>
            <dl>
              <dt>{{$t('KL.prodcutPrice')}}：</dt>
              <dd>
                <span class="symbol">{{ userInfo.symbol }}</span>
                <span class="price">{{ userInfo.status === 1 ? Number(proInfo.price) :$t('KL.LoginToSeePrices')}}</span>
              </dd>
            </dl>
            <div class="cart-box">
              <el-button @click="getPageDetail(proInfo,0)" type="primary">{{$t('KL.PageUp')}}</el-button>
              <el-button @click="getPageDetail(proInfo,1)" type="primary">{{$t('KL.PageDown')}}</el-button>
              <el-badge :value="0" class="item" hidden>
                <el-button @click="onAddCart(proInfo)" type="primary">{{$t('KL.addCart')}}</el-button>
              </el-badge>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="pro-detail-box">
      <p v-for="(item, index) in proInfo.imgList" :key="index">
        <img class="imgs" :src="item.filePath" alt srcset />
      </p>
    </div>-->
  </div>
</template>
<script>
import { deletCartsApi, addCartsApi } from '@/api/cart'
import { getPageDetail, getDetail } from '@/api/product'
import picZoom from '@/components/themeTemplate/pc_components/productBox/picZoom.vue'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { mapGetters, mapActions } from 'vuex'
import transformMark from '@/utils/water'
export default {
  name: 'proDetail',
  components: {
    picZoom,
    swiper,
    swiperSlide
  },

  data() {
    return {
      isTrue: false,
      activeImg: '',
      proInfo: {},
      activeIndex: 0,
      swiperOption: {
        slidesPerView: 4,
        initialSlide: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        observer: false, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, // 修改swiper的父元素时，自动初始化swiper
        imgArr: [],
        preventLinksPropagation: false // 阻止点击事件冒泡
      }
    }
  },
  computed: {
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    }),

    ...mapGetters('operate', {
      gettingCart: 'getting_cart'
    }),
    // 已加购还是取消加购
    ifAdd() {
      return coNu => {
        return this.gettingCart.findIndex(item => item.co_Nu === coNu) > -1
      }
    },
    defaultImg() {
      return this.proInfo.imgUrl
    }
  },
  created() {
    this.initData()
  },

  mounted() {
    sessionStorage.setItem('detail', true)
  },
  methods: {
    ...mapActions('operate', {
      addCart: 'add_cart',
      deleteCart: 'delete_cart'
    }),
    onAddCart(item) {
      if (!this.userInfo.id) {
        this.$message.warning(this.$t('KL.PleaseLoginBeforeAdding'))
        return
      }
      let obj = {
        client_Nu: this.userInfo.client_Nu || '',
        userId: this.userInfo.id,
        co_Nu: item.co_Nu,
        number: 1
      }
      addCartsApi(obj).then(res => {
        if (res.success) {
          this.$message.success(this.$t('KL.AddSuccess'))
        } else {
          this.$message.error(this.$t('KL.AddError') + res)
        }
      })
    },
    click(proInfo) {
      if (!this.ifAdd(proInfo.co_Nu)) {
        // 添加商品
        if (this.userInfo) {
          addCartsApi({
            client_Nu: proInfo.client_Nu,
            userId: this.userInfo.id,
            co_Nu: proInfo.co_Nu,
            number: 1
          }).then(res => {
            if (res.success) {
              this.addCart({
                client_Nu: this.userInfo.client_Nu,
                userId: this.userInfo.id,
                co_Nu: proInfo.co_Nu,
                fa_No: proInfo.fa_No,
                imgUrl: proInfo.imgUrl,
                in_En: proInfo.in_En,
                number: 1,
                ou_Lo: proInfo.ou_Lo,
                pr_En_Na: proInfo.pr_En_Na,
                pr_Na: proInfo.pr_Na,
                price: proInfo.price
              })
            }
          })
        }
      } else {
        // 删除商品
        if (this.userInfo) {
          deletCartsApi({
            userId: this.userInfo.id,
            coNus: [proInfo.co_Nu]
          }).then(res => {
            if (res.success) {
              this.deleteCart(proInfo.co_Nu)
            }
          })
        }
      }
    },

    getPageDetail(item, num) {
      let obj = {
        client_Nu: this.userInfo.client_Nu,
        id: item.id,
        category: num
      }
      getPageDetail(obj).then(res => {
        if (res.data) {
          transformMark(res.data.imgUrl).then(result => {
            res.data.imgUrl = result
          })

          // 产品列表
          res.data.imgList.forEach(item => {
            transformMark(item.filePath).then(result => {
              item.filePath = result
            })
          })

          // 包装列表
          res.data.packImgList.forEach(item => {
            transformMark(item.filePath).then(result => {
              item.filePath = result
            })
          })

          // 实样图列表
          res.data.realImgList.forEach(item => {
            transformMark(item.filePath).then(result => {
              item.filePath = result
            })
          })
          this.proInfo = res.data
        } else {
          this.$message.warning(this.$t('KL.NoMore'))
        }
      })
    },
    initData() {
      const param = {
        client_Nu: this.userInfo.client_Nu,
        co_Nu: this.$route.query.coNu
      }
      getDetail(param).then(res => {
        if (res.data) {
          transformMark(res.data.imgUrl).then(result => {
            res.data.imgUrl = result
          })

          // 产品列表
          res.data.imgList.forEach(item => {
            transformMark(item.filePath).then(result => {
              item.filePath = result
            })
          })

          // 包装列表
          res.data.packImgList.forEach(item => {
            transformMark(item.filePath).then(result => {
              item.filePath = result
            })
          })

          // 实样图列表
          res.data.realImgList.forEach(item => {
            transformMark(item.filePath).then(result => {
              item.filePath = result
            })
          })
        }
        this.proInfo = res.data
      })
    },
    // 图片经过时，设置大图
    hoverImg(data) {
      this.activeImg = data.filePath
    },
    // 图片点击时，设置大图
    setImg(data) {
      this.activeImg = data.filePath
    }
  }
}
</script>
<style lang="scss" scoped>
.detail-box {
  overflow: hidden;
  margin: 0 0 20px 0;

  .detail-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background: linear-gradient(0deg, #007bc3, #39adf6);
    border-radius: 10px 10px 0px 0px;
    line-height: 40px;
    font-size: 16px;
    color: #ffffff;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .detail-info {
    min-height: 708px;
    background-color: #fff;
    border: 1px solid #0077c3;
    border-radius: 0px 0px 10px 10px;
    border-top: 0;
    padding: 20px 50px;
    box-sizing: border-box;
    font-size: 12px;
    color: #828282;
  }
}
.pro-box {
  display: flex;

  & .img-info {
    width: 536px;

    & .goods-img {
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: solid 1px #dcdfe6;
    }

    .swiper {
      position: relative;
      box-sizing: border-box;
      margin-top: 10px;
      width: 100%;
      height: 65px;

      & .swiper-container {
        width: 86%;
        padding: 0 7%;
        height: 67px;
        position: relative;

        & .swiper-wrapper {
          height: 67px;
          display: flex;
          align-items: center;
        }

        & .swiper-slide {
          width: 20% !important;

          & .item-img {
            margin: 0 5px;
            height: 61px;
            border: solid 1px #dcdfe6;
            padding: 2px;
            display: flex;
            justify-content: center;

            & img {
              width: auto;
              max-width: 100%;
              max-height: 65px;
            }
          }
        }
      }
    }
  }

  & .pro-info {
    flex: 1;
    padding-left: 80px;
    color: #333;

    & h3 {
      display: inline-block;
      width: 460px;
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 10px;
      min-height: 40px;
      max-height: 120px;
      word-break:break-all;
    }

    & dl {
      width: 100%;
      display: flex;
      line-height: 40px;

      & dt,
      dd {
        font-size: 16px;
        color: #333;
        span {
          word-break: break-all;
        }
        & .tip {
          font-size: 14px;
        }
      }

      & .symbol {
        color: #ff3e3e;
        font-size: 16px;
      }

      & .price {
        color: #ff3e3e;
        font-size: 24px;
        font-weight: bold;
      }
    }

    .cart-box {
      display: flex;
      align-items: center;
      width: 100%;
      padding-top: 20px;
      .item {
        margin-left: 10px;
      }
    }
  }
}

.pro-detail-box {
  margin-top: 15px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;

  & p {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;

    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>