<!--
 * @Description: 联系我们
 * @Author: zml
 * @Date: 2023-03-28 17:35:27
 * @LastEditors: zml
 * @LastEditTime: 2023-04-04 15:08:23
-->
<template>
  <div>
    <div class="container">
      <div class="company-profile-box">
        <div class="company-profile-title">
          <div>{{$t('KL.CardTit.ContactUs')}}</div>
          <div></div>
        </div>
        <div class="company-profile-info">
          <div class="detail" v-html="$hs(datas.content,datas.enContent, datas.enContent)||''"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'pageDetail',
  props: {
    datas: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState('operate', ['language'])
  }
}
</script>
<style lang="scss" scoped>
.company-profile-box {
  overflow: hidden;
  margin: 0 0 20px 0;

  .company-profile-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background: linear-gradient(0deg, #007bc3, #39adf6);
    border-radius: 10px 10px 0px 0px;
    line-height: 40px;
    font-size: 16px;
    color: #ffffff;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .company-profile-info {
    min-height: 708px;
    background-color: #fff;
    border: 1px solid #0077c3;
    border-radius: 0px 0px 10px 10px;
    border-top: 0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;
    color: #828282;
  }
}
.detail {
  :deep(img) {
    max-width: 100%;
  }
}
</style>
