<template>
  <div class="container product">
    <div class="left-box">
      <div class="contact-us">
        <div class="contact-title">{{$t('KL.CardTit.ContactUs')}}</div>
        <div class="contact-info">
          <div>
            <span style="font-weight:bold">{{$t('companyTel')}}:</span>
            <span>{{getSetting.telPhone}}</span>
          </div>
          <div>
            <span style="font-weight:bold">{{$t('KL.mobile')}}:</span>
            <span>{{getSetting.mobile}}</span>
          </div>
          <div>
            <span style="font-weight:bold">{{$t('KL.Wechat')}}:</span>
            <span>{{getSetting.weChatNo}}</span>
          </div>
          <div>
            <span style="font-weight:bold">QQ:</span>
            <span>{{getSetting.qq}}</span>
          </div>
          <div>
            <span style="font-weight:bold">{{$t('KL.Email')}}:</span>
            <span>{{getSetting.homeMail}}</span>
          </div>
        </div>
      </div>
      <div class="search-box">
        <el-input :placeholder="$t('KL.placeholderProductKey')" v-model="productInputValue">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearchProduct"></i>
        </el-input>
      </div>
      <div class="member-box">
        <div class="member-title">{{$t('KL.CardTit.MemberArea')}}</div>
        <div class="member-info">
          <div v-if="!userInfo.id">
            <el-form label-position="top" label-width="80px" ref="loginForm" :model="loginForm" :rules="loginRules">
              <el-form-item :label="$t('KL.MemberNumber')+'：'" required prop="userAccount">
                <el-input v-model="loginForm.userAccount" :placeholder="$t('KL.pleaseMemberNumber')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('KL.Password')+'：'" required prop="userPassword">
                <el-input
                  type="password"
                  autocomplete="new-password"
                  v-model="loginForm.userPassword"
                  :placeholder="$t('KL.PleaseEnterPassword')"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btn">
              <div class="login" @click="onLogin">{{$t('KL.Login')}}</div>
              <div class="sign" @click="onSign">{{$t('KL.Register')}}</div>
            </div>
          </div>
          <div v-else>
            <div class="login-success">{{$t('KL.Hello')}}，{{userInfo.userAccount}}</div>
            <div class="login-success order-btn" @click="onMyOrder">{{$t('KL.MyOrder')}}</div>
            <div class="login-success out-btn" @click="onLogOut">{{$t('KL.logOut')}}</div>
          </div>
        </div>
      </div>
      <div class="classify-box">
        <div class="classify-title">{{$t('KL.CardTit.Category')}}</div>
        <div class="classify-info">
          <div class="all" @click="onSearchAll">{{$t('KL.All')}}</div>
          <el-menu class="el-menu-vertical-demo" :default-openeds="openeds">
            <el-submenu v-for="(item,index) in setMenus" :key="item.id + 'index'" :index="`${index}`">
              <template slot="title">
                <div>
                  <span @click="handleClose(item)">
                    <i class="icon"></i>
                    <span>{{$hs(item.cl_Na,item.cl_Ne_Na,item.threeLanguage)||''}}</span>
                  </span>
                  <i v-if="item.children.length>0" class="el-icon-arrow-down"></i>
                </div>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  v-for="it in item.children"
                  :key="it.id + 'children'"
                  :index="item.cl_Nu + '-' +it.cl_Nu"
                  @click="handleClose(it)"
                >{{$hs(it.cl_Na,it.cl_Ne_Na,it.threeLanguage)||''}}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="hot-box">
        <div class="hot-title">
          <div>{{$t('KL.Product')}} ( {{$hs(classifyItem.cl_Na,classifyItem.cl_Ne_Na,classifyItem.threeLanguage)||classifyItem.cl_Nu}} )</div>
          <div></div>
        </div>
        <div class="hot-info">
          <div class="item-box" v-if="productList.length>0">
            <div class="hot-item" v-for="item in productList" :key="item.id">
              <div class="img-box" @click="$router.push('/proDetail?coNu='+ item.co_Nu)">
                <img :src="item.imgUrl" alt srcset />
              </div>
              <div class="hot-describe">
                <div>
                  <div>{{item.co_Nu}}</div>
                  <div class="name">{{$hs(item.pr_Na,item.pr_En_Na,item.basicThreeLanguage)||''}}</div>
                </div>
                <div class="add-cart" @click="onAddCart(item)">
                  <div class="add">{{$t('KL.addCart')}}</div>
                  <div class="cart-icon">
                    <img src="../../../../assets/KLXcart.png" alt width="18" srcset />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <el-empty :description="$t('noData')" v-else></el-empty>
          <div class="pagination-box">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryParam.pageNo"
              :page-sizes="[5, 10, 15, 20,25]"
              :page-size="queryParam.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalRows"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <reg-modal ref="regModal"></reg-modal>
  </div>
</template>
<script>
import { ProductClassWebGetTree } from '@/api/home'
import { batchAddApi, addCartsApi } from '@/api/cart'
import { queryPage, queryPageByDiscount } from '@/api/product'
import { LoginMemberNoCode } from '@/api/login'
import regModal from '@/components/themeTemplate/pc_components/memberModal/regModal.vue'
import { mapGetters, mapActions } from 'vuex'
import transformMark from '@/utils/water'
export default {
  name: 'pageProduct',
  components: {
    regModal
  },
  data() {
    return {
      productInputValue: '',
      loginForm: {
        userAccount: '',
        userPassword: ''
      },
      loginRules: {},
      memberForm: {
        name: '',
        region: '',
        type: ''
      },
      currentPage4: 4,
      // 产品分类
      setMenus: [],
      classifyItem: {
        cl_Na: '全部',
        cl_Ne_Na: 'All',
        threeLanguage: 'All'
      },
      openeds: [],
      productList: [],
      queryParam: {
        pageNo: 1,
        pageSize: 25,
        sortField: '',
        sortOrder: '',
        cl_Nu: '',
        isAnd: true,
        conditionals: [],
        startTime: null,
        category: 1,
        coNus: []
      },
      totalRows: 0,
      totalPage: 0,
      discount: '',
      isImg: false // 图搜
    }
  },
  computed: {
    ...mapGetters('setting', {
      getSetting: 'get_setting',
      getMenus: 'get_menus'
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    }),
    // 小选、全选相互影响
    checkAll: {
      set(state) {
        // 将全选的状态值赋值给小选
        this.productList.forEach(item => (item.isCheck = state))
      },
      get() {
        return this.productList.every(item => item.isCheck === true)
      }
    }
  },
  watch: {
    $route(to) {
      if (to.name === 'product') {
        this.queryParam.category = Number(to.query.Hot) || 1
        this.queryParam.cl_Nu = this.$route.query.clNu || ''
        this.discount = to.query.discount
        this.classifyItem = { ...this.classifyItem, ...to.query }
        this.queryParam.coNus = to.query.coNus ? to.query.coNus.split('\n') : []
        this.initData()
      }
    }
  },
  created() {
    // Event Bus搜索事件
    this.$bus.$on('searchProduct', keywords => {
      this.queryParam.conditionals = this.buildConditionals(keywords)
      this.productInputValue = keywords
      this.queryParam.pageNo = 1
    })
    let keyVal = sessionStorage.getItem('searchProduct')
    if (keyVal) {
      this.productInputValue = keyVal
      this.queryParam.conditionals = this.buildConditionals(keyVal)
      this.queryParam.pageNo = 1
    }
    sessionStorage.removeItem('searchProduct')
    // 路由传参
    let queryData = this.$route.query
    this.queryParam.category = Number(queryData.Hot) || 1
    this.queryParam.cl_Nu = queryData.clNu || ''
    this.discount = queryData.discount
    this.classifyItem = { ...this.classifyItem, ...queryData }
    this.queryParam.coNus = queryData.coNus ? queryData.coNus.split('\n') : []
    this.initData()
    if (queryData.isImg) {
      this.isImg = queryData.isImg
      this.imgsSearch()
      return
    }
    this.initMenus()
  },
  mounted() {
    // 高级搜索
    this.$bus.$on('advancedSearch', FormData => {
      this.queryParam.conditionals = this.advancedSearchFun(FormData)
    })

    this.getActivePageNo()
  },
  destroyed() {
    sessionStorage.removeItem('detail')
  },
  methods: {
    ...mapActions('operate', {
      batchAddCart: 'batch_add_cart'
    }),
    ...mapActions('user', ['set_userInfo', 'logout']),

    // 分类筛选
    handleClose(item) {
      this.classifyItem = item
      this.queryParam.pageNo = 1
      this.queryParam.cl_Nu = item.cl_Nu
      this.queryParam.conditionals = []
      this.queryParam.keywords = ''
      this.initData()
    },
    // 添加购物车
    onAddCart(item) {
      if (!this.userInfo.id) {
        this.$message.warning(this.$t('KL.PleaseLoginBeforeAdding'))
        return
      }
      let obj = {
        client_Nu: this.userInfo.client_Nu || '',
        userId: this.userInfo.id,
        co_Nu: item.co_Nu,
        number: 1
      }
      addCartsApi(obj).then(res => {
        if (res.success) {
          this.$message.success(this.$t('KL.AddSuccess'))
        } else {
          this.$message.error(this.$t('KL.AddError') + res.message)
        }
      })
    },

    // 构建复杂查询条件
    advancedSearchFun(formData) {
      const conditionals = []
      this.queryParam.sortField = ''
      this.queryParam.sortOrder = ''
      this.queryParam.isAnd = true
      this.queryParam.keywords = formData.remark || ''
      for (const key in formData) {
        if (key === 'date1') {
          conditionals.push({
            conditionalType: 3,
            fieldName: 'createdTime',
            fieldValue: formData[key] + ' 00:00:00'
          })
        } else if (key === 'date2') {
          conditionals.push({
            conditionalType: 5,
            fieldName: 'createdTime',
            fieldValue: formData[key] + ' 23:59:59'
          })
        } else if (key === 'cl_Nu') {
          conditionals.push({
            conditionalType: 1,
            fieldName: 'cl_Nu',
            fieldValue: formData[key]
          })
        } else if (key === 'price1') {
          conditionals.push({
            conditionalType: 3,
            fieldName: 'price',
            fieldValue: formData[key]
          })
        } else if (key === 'price2') {
          conditionals.push({
            conditionalType: 5,
            fieldName: 'price',
            fieldValue: formData[key]
          })
        } else if (key === 'pr_Le') {
          conditionals.push({
            conditionalType: 1,
            fieldName: 'pr_Le',
            fieldValue: formData[key]
          })
        } else if (key === 'pr_Wi') {
          conditionals.push({
            conditionalType: 1,
            fieldName: 'pr_Wi',
            fieldValue: formData[key]
          })
        } else if (key === 'pr_Hi') {
          conditionals.push({
            conditionalType: 1,
            fieldName: 'pr_Hi',
            fieldValue: formData[key]
          })
        } else if (key === 'sort') {
          this.queryParam.sortField = formData[key]
          this.queryParam.sortOrder = 'Dscend'
        }
      }

      return conditionals
    },
    // 登录
    onLogin() {
      const _this = this
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          LoginMemberNoCode(this.loginForm)
            .then(res => {
              if (res.success) {
                this.$message.success(_this.$t('KL.LoginSuccess'))

                _this.set_userInfo(res.data)

                window.location.reload()
              } else {
                this.$message.error(_this.$t('KL.LoginError') + res)
              }
            })
            .catch(err => {
              this.$message.error(_this.$t('KL.LoginError') + err)
            })
        } else {
          return false
        }
      })
    },

    // 退出登录
    onLogOut() {
      const _this = this
      this.$confirm(_this.$t('KL.isLogOut'), this.$t('KL.SystemPrompt'), {
        confirmButtonText: _this.$t('KL.Confirm'),
        cancelButtonText: _this.$t('KL.Cancel'),
        type: 'warning'
      })
        .then(() => {
          _this.logout()
          window.location.reload()
        })
        .catch(() => {})
    },

    // 我的订单
    onMyOrder() {
      this.$router.push({
        path: '/order'
      })
    },

    // 注册
    onSign() {
      this.$refs.regModal.open()
    },

    // 搜索全部
    onSearchAll() {
      this.queryParam = {
        pageNo: 1,
        pageSize: 25,
        sortField: '',
        sortOrder: '',
        cl_Nu: '',
        isAnd: true,
        conditionals: [],
        startTime: null,
        category: 1,
        keywords: ''
      }
      this.classifyItem = {
        cl_Na: '全部',
        cl_Ne_Na: 'All',
        threeLanguage: 'All'
      }
      this.initData()
    },

    // 搜索框搜索构建复杂查询条件
    onSearchProduct() {
      const conditionals = []
      this.queryParam.pageNo = 1
      this.queryParam.isAnd = false
      this.queryParam.keywords = ''
      const fields = ['co_Nu', 'fa_No', 'pr_Na', 'cl_Na', 'pr_En_Na']
      if (this.productInputValue) {
        fields.forEach(item => {
          conditionals.push({
            conditionalType: 1,
            fieldName: item,
            fieldValue: this.productInputValue
          })
        })
      }
      this.queryParam.conditionals = conditionals
      this.initData()
    },
    // 初始化数据
    initData() {
      // 带上客户编号
      this.queryParam.client_Nu = this.userInfo.client_Nu || ''
      if (this.discount) {
        queryPageByDiscount(this.queryParam).then(res => {
          if (res.success) {
            this.productList = res.data.rows || []
            this.productList.forEach(item => {
              transformMark(item.imgUrl).then(result => {
                item.imgUrl = result
              })
            })
            this.totalRows = res.data.totalRows
            this.totalPage = res.data.totalPage
          }
        })
      } else {
        queryPage(this.queryParam).then(res => {
          if (res.success) {
            this.productList = res.data.rows || []
            this.productList.forEach(item => {
              transformMark(item.imgUrl).then(result => {
                item.imgUrl = result
              })
            })
            this.totalRows = res.data.totalRows
            this.totalPage = res.data.totalPage
          }
        })
      }
    },

    // 构建复杂查询条件
    buildConditionals(keywords) {
      const conditionals = []
      this.queryParam.keywords = ''
      this.queryParam.isAnd = false
      const fields = ['co_Nu', 'fa_No', 'pr_Na', 'cl_Na', 'pr_En_Na']
      fields.forEach(item => {
        conditionals.push({
          conditionalType: 1,
          fieldName: item,
          fieldValue: keywords
        })
      })
      return conditionals
    },

    // 分页大小操作
    handleSizeChange(pageSize) {
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = pageSize
      this.initData()
    },
    getActivePageNo() {
      let reg = sessionStorage.getItem('detail')
      if (reg) {
        // 从商品详情回来
        let activePageNo = sessionStorage.getItem('activePageNo')
        this.queryParam.pageNo = Number(activePageNo)
      } else {
        // 从其他页面第一次进入列表页，清掉缓存里面的数据
        this.queryParam.pageNo = 1
        sessionStorage.removeItem('activePageNo')
      }
    },
    // 分页翻页操作
    handleCurrentChange(pageNo) {
      sessionStorage.setItem('activePageNo', pageNo)
      this.queryParam.pageNo = pageNo
      this.initData()
    },
    // 分类模块点击操作
    classifyClick(cl_Nu) {
      this.queryParam.cl_Nu = cl_Nu
      this.queryParam.conditionals = []
      this.$refs.advanced.reset()
      this.pageNo = 1
      this.initData()
    },

    // 添加选中的产品到购物车
    addSelectdToCart() {
      // 必须登陆后才能添加购物车
      if (this.userInfo) {
        this.$message({
          message: this.$t('noLogin'),
          type: 'warning'
        })
        return
      }
      // 找出被选中的记录
      const seletedProducts = this.productList.filter(
        item => item.isCheck === true
      )
      let newArr = []
      const proLists = []
      // 判断当前页是否选中过产品
      if (seletedProducts.length > 0) {
        seletedProducts.forEach(item => {
          newArr.push({
            co_Nu: item.co_Nu,
            number: 1
          })
          proLists.push({
            client_Nu: this.userInfo.client_Nu,
            userId: this.userInfo.id,
            co_Nu: item.co_Nu,
            fa_No: item.fa_No,
            imgUrl: item.imgUrl,
            in_En: item.in_En,
            number: 1,
            ou_Lo: item.ou_Lo,
            pr_En_Na: item.pr_En_Na,
            pr_Na: item.pr_Na,
            price: item.price
          })
        })
        batchAddApi({
          userId: this.userInfo.id,
          client_Nu: this.userInfo.client_Nu,
          cartItem: newArr
        }).then(res => {
          if (res.success) {
            this.$message({
              message: this.$t('success'),
              type: 'success'
            })
            this.batchAddCart(proLists)
          }
        })
      }
    },
    // 选中当前页全部产品
    selectAll() {
      this.productList.forEach(item => {
        item.isCheck = this.checkAll
      })
    },
    // 获取种类列表
    initMenus() {
      ProductClassWebGetTree()
        .then(res => {
          if (res.success) {
            this.setMenus = res.data
            this.openeds = res.data.map((item, index) => {
              return String(index)
            })
          } else {
            this.$message(res.message)
          }
        })
        .catch(err => {
          this.$message({ err })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.product {
  display: flex;
  .left-box {
    width: 180px;
    margin: 0 20px 0 0;
    .contact-us {
      overflow: hidden;
      margin: 0 0 20px 0;

      .contact-title {
        height: 40px;
        background: linear-gradient(0deg, #007bc3, #39adf6);
        border-radius: 10px 10px 0px 0px;
        line-height: 40px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 10px;
        box-sizing: border-box;
      }
      .contact-info {
        height: 180px;
        background-color: #fff;
        border: 1px solid #0077c3;
        border-radius: 0px 0px 10px 10px;
        border-top: 0;
        padding: 10px 6px;
        box-sizing: border-box;
        font-size: 12px;
        color: #828282;
        & > div {
          margin-bottom: 10px;
        }
      }
    }
    .search-box {
      margin: 0 0 10px 0;
      :deep input {
        height: 44px;
      }
      .el-input__icon {
        font-size: 18px;
        cursor: pointer;
        &:hover {
          font-size: 24px;
        }
      }
    }
    .member-box {
      overflow: hidden;
      margin: 0 0 20px 0;

      .member-title {
        height: 40px;
        background: linear-gradient(0deg, #007bc3, #39adf6);
        border-radius: 10px 10px 0px 0px;
        line-height: 40px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 10px;
        // text-align: center;
        box-sizing: border-box;
      }
      .member-info {
        height: 171px;
        background-color: #fff;
        border: 1px solid #0077c3;
        border-radius: 0px 0px 10px 10px;
        border-top: 0;
        padding: 10px;
        box-sizing: border-box;
        font-size: 12px;
        color: #828282;
        :deep(.el-form-item) {
          margin: 0 !important;
          .el-form-item__label {
            line-height: normal;
            padding: 0;
          }
          .el-input__inner {
            height: 28px;
          }
          .el-form-item__error {
            display: none;
          }
        }

        .login-success {
          height: 28px;
          line-height: 30px;
          border-bottom: 1px solid #eee;
          font-size: 14px;
          margin: 0 0 20px 0;
          padding: 0 10px;
          &.order-btn,
          &.out-btn {
            cursor: pointer;
            &:hover {
              background-color: #eee;
              border-radius: 2px;
              color: #289ee7;
            }
          }
        }
        .btn {
          display: flex;
          justify-content: space-around;
          margin-top: 6px;
          & > div {
            width: 68px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            border-radius: 12px;
            cursor: pointer;
          }
          .login {
            background-color: #00a9b4;
          }
          .sign {
            background-color: #3e97d1;
          }
        }
      }
    }
    .classify-box {
      overflow: hidden;
      margin: 0 0 20px 0;

      .classify-title {
        height: 40px;
        background: linear-gradient(0deg, #007bc3, #39adf6);
        border-radius: 10px 10px 0px 0px;
        line-height: 40px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 10px;
        box-sizing: border-box;
      }
      .classify-info {
        height: 914px;
        background-color: #fff;
        border: 1px solid #0077c3;
        border-radius: 0px 0px 10px 10px;
        border-top: 0;
        padding: 10px;
        box-sizing: border-box;
        font-size: 12px;
        color: #828282;
        overflow: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
          // height: 5px;
        }
        /* 滚动条上的滚动滑块 */
        &::-webkit-scrollbar-thumb {
          background-color: #49b1f5;
          border-radius: 32px;
        }
        /* 滚动条轨道 */
        &::-webkit-scrollbar-track {
          background-color: #dbeffd;
          border-radius: 32px;
        }

        .all {
          background-color: #ecf5ff;
          padding: 5px 10px;
          font-size: 16px;
          color: #222;
          font-weight: bold;
          text-align: center;
          margin: 0 0 5px 0;
          cursor: pointer;
          &:hover {
            color: #0077c3;
            background-color: #eee;
          }
        }

        :deep(.el-menu-vertical-demo) {
          border-right: 0px !important;

          .el-submenu__title {
            padding: 0 !important;
            height: 32px;
            line-height: 30px;
            color: #000;
            border-radius: 6px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            & > div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              &:hover {
                & > i {
                  color: #fff;
                  font-weight: bold;
                  background-color: #0f88d0;
                  width: 60px;
                }
              }
              & > span {
                width: 140px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              & > i {
                flex: 1;
                display: inline-block;
                margin: 0;
                font-size: 16px;
                line-height: 32px;
                &:hover {
                  color: #fff;
                  font-weight: bold;
                  background-color: #0f88d0;
                }
              }
            }

            i.icon {
              display: inline-block;
              width: 6px;
              height: 6px;
              background: #444;
              border-radius: 50%;
              margin: 0 6px 0 6px;
            }
            span {
              font-size: 15px !important;
            }
          }

          .el-menu-item {
            min-width: 100%;
            padding: 0 18px !important;
            height: 32px;
            line-height: 30px;
            border-radius: 6px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #666;
          }

          .el-menu-item-group__title {
            padding: 0 !important;
          }
          .el-submenu__icon-arrow {
            display: none;
          }
        }
      }
    }
  }

  .right-box {
    flex: 1;
    .hot-box {
      overflow: hidden;
      margin: 0 0 20px 0;

      .hot-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        background: linear-gradient(0deg, #007bc3, #39adf6);
        border-radius: 10px 10px 0px 0px;
        line-height: 40px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .hot-info {
        position: relative;
        width: 1000px;
        height: 1435px;
        background-color: #fff;
        border: 1px solid #0077c3;
        border-radius: 0px 0px 10px 10px;
        border-top: 0;
        padding: 20px;
        box-sizing: border-box;
        font-size: 12px;
        color: #828282;

        .item-box {
          display: flex;
          flex-flow: wrap;

          .hot-item {
            width: 180px;
            height: 233px;
            // background-color: #eee;
            margin: 0 14px 20px 0;
            border: 1px solid #d6d6d6;
            box-sizing: border-box;
            overflow: hidden;
            &:nth-child(5n) {
              margin-right: 0;
            }
            .img-box {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 178px;
              height: 135px;
              overflow: hidden;
              cursor: pointer;
              &:hover {
                img {
                  transition-duration: 0.5s;
                  transform: scale(1.3);
                }
              }

              img {
                max-width: 100%;
                max-height: 135px;
                transition-duration: 0.5s;
              }
            }
            .hot-describe {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              height: 70px;
              margin: 10px 0 0 0;
              .name {
                width: 160px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: center;
              }
              & > div {
                text-align: center;
              }
              .add-cart {
                display: flex;
                align-items: center;
                width: 102px;
                height: 28px;
                cursor: pointer;
                .add {
                  flex: 1;
                  height: 100%;
                  line-height: 28px;
                  font-size: 12px;
                  color: #ffffff;
                  text-shadow: 0.5px 0.87px 2px 0px rgba(0, 0, 0, 0.2);
                  background-color: #0985d0;
                }
                .cart-icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 28px;
                  height: 100%;
                  line-height: 28px;
                  background-color: #ffc000;
                  color: #ffffff;
                }
              }
            }
          }
        }
        .pagination-box {
          position: absolute;
          bottom: 80px;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>
