<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-04-03 18:38:43
-->
<template>
  <div>
    <page-home-tow v-if="cutTemplates === 'themeTemplate'"></page-home-tow>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'homePage',
  mixins: [mixins],

  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>
</style>