<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-04-03 18:39:00
-->
<template>
  <div class="cart-box">
    <page-cart-tow v-if="cutTemplates === 'themeTemplate'"></page-cart-tow>
  </div>
</template>

<script>
import mixins from '@/utils/mixins'
export default {
  name: 'cartPage',
  mixins: [mixins]
}
</script>
<style lang="scss" scoped>
.cart-box {
  width: 100%;
  // background: #f1f3f6;
}
</style>