<template>
  <div>
    <page-address-tow v-if="cutTemplates === 'themeTemplate'"></page-address-tow>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'addressPage',
  mixins: [mixins],
  data() {
    return {}
  }
}
</script>