<!--
 * @Description: 
 * @Author: zml
 * @Date: 2023-03-28 11:17:06
 * @LastEditors: zml
 * @LastEditTime: 2023-04-03 18:24:16
-->
<template>
  <div class="header-box">
    <nav-bar></nav-bar>
  </div>
</template>

<script>
import navBar from '@/components/themeTemplate/pc_components/navBar/index'
export default {
  components: {
    navBar
  }
}
</script>
<style lang="scss" scoped>
</style>