<template>
  <div class="bgTow">
    <el-container>
      <el-header height="auto">
        <layout-header></layout-header>
      </el-header>
      <el-main>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </el-main>
      <el-footer height="auto">
        <layoutFooter></layoutFooter>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import {
  ProductClassWebGetTree,
  SysWebSiteConfigGetDetail
} from '@/api/home'
import layoutHeader from './layout/layoutHeader'

import layoutFooter from './layout/layoutFooter.vue' // 底部风格二
export default {
  name: 'pcLayout',
  components: {
    layoutHeader,
    layoutFooter
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('operate', ['language'])
  },
  methods: {
    ...mapActions('setting', {
      setMenus: 'set_menus',
      setLanguageConfig: 'set_language_config'
    }),
    ...mapActions('operate', {
      setLanguage: 'set_language'
    }),
    async init() {
      let _this = this

      // 站点语言
      await SysWebSiteConfigGetDetail().then(res => {
        if (res.success) {
          _this.setLanguageConfig(res.data)
          if (!window.localStorage.getItem('i18n')) {
            if (res.data.defaultLanguage === 'zh-Hans') {
              _this.setLanguage('zh')
              window.location.reload()
            } else {
              _this.setLanguage(res.data.defaultLanguage)
              window.location.reload()
            }
          }
        } else {
          _this.$message(res.message)
        }
      }).catch(err => {
        _this.$message({ err })
      })

      await ProductClassWebGetTree().then(res => {
        if (res.success) {
          _this.setMenus(res.data)
        } else {
          _this.$message(res.message)
        }
      }).catch(err => {
        _this.$message({ err })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.bgTow {
  background: url('../../assets/bg.png') no-repeat;
  background-size: 100% 100%;
}

:deep .container {
  width: 1200px;
}

.el-header,
.el-footer,
.el-main {
  padding: 0;
}

.el-main {
  min-height: 600px;
}
</style>
