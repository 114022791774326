import VueI18n from 'vue-i18n'
import Vue from 'vue'

//引入element的语言包
import enLocale from 'element-ui/lib/locale/lang/en' //英文
import zhLocale from 'element-ui/lib/locale/lang/zh-CN' //中文
import ruLocale from 'element-ui/lib/locale/lang/ru-RU' //俄语

// 引入需要语言包也可是js文件，export default抛出语言包的对象
import en from './lang/saas-en.js'
import zh from './lang/saas-zh-CN.js'
import ru from './lang/saas-ru-RU.js'


Vue.use(VueI18n)
let lang = localStorage.getItem('i18n') || 'en' || 'ru'

export default new VueI18n({
    locale: lang,
    messages: {
        en: Object.assign(en, enLocale),
        zh: Object.assign(zh, zhLocale),
        ru: Object.assign(ru, ruLocale),
    }
})