<template>
  <div class="container">
    <div class="company-profile-box">
      <div class="company-profile-title">
        <div>{{ $t('orderDetails') }}</div>
        <div class="my-order" @click="$router.push('/order')">{{ $t('KL.MyOrder') }}</div>
      </div>
      <div class="company-profile-info">
        <div class="order-info">
          <order-box :orderInfo="orderInfo" :isShowBtn="false"></order-box>
        </div>
        <div class="order-detail-list" v-if="orderInfo.orderDetails.length>0">
          <el-table :data="orderInfo.orderDetails">
            <el-table-column :label="$t('ID')" type="index" prop="index" width="120" align="center"></el-table-column>
            <el-table-column :label="$t('picture')" width="120" align="center">
              <template slot-scope="scope">
                <div class="pro-img">
                  <img :src="scope.row.imgUrl || image" alt srcset />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="co_Nu" :label="$t('ItemNO')" width="100" align="center"></el-table-column>
            <el-table-column :label="$t('name')" align="center">
              <template slot-scope="scope">{{ $hs(scope.row.pr_Na, scope.row.pr_En_Na,scope.row.basicThreeLanguage )||''}}</template>
            </el-table-column>
            <el-table-column prop="price" :label="$t('price')" align="center">
              <template slot-scope="scope">
                <div class="price">{{ userInfo.symbol }}{{ userInfo.status === 1 ? scope.row.price : $t('viewPrice') }}</div>
              </template>
            </el-table-column>
            <el-table-column :label="$t('KL.OuterQTY')+'//(PCS)'" prop="packingCapacity" width="180" align="center" :render-header="renderheader">
              <template slot-scope="scope">{{ scope.row.number * scope.row.ou_Lo }}</template>
            </el-table-column>
            <el-table-column :label="$t('CBM')" width="180" align="center">
              <template slot-scope="scope">{{ scope.row.bulk_Stere }}</template>
            </el-table-column>
            <el-table-column :label="$t('CTNS')" prop="number" width="110" align="center"></el-table-column>
            <el-table-column :label="$t('total')" prop="total" width="110" align="center">
              <template slot-scope="scope">
                <div class="price">
                  {{ userInfo.symbol }}{{ userInfo.status === 1 ? (scope.row.number *
                  scope.row.price * scope.row.ou_Lo).toFixed(4) : $t('viewPrice') }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="action-list">
            <div class="total">
              <div class="count">
                <p>
                  {{ $t('totalRecords') }}：
                  <span>{{orderInfo.orderDetails.length}}</span>
                </p>
                <p>
                  {{ $t('totalCTNS') }}：
                  <span>{{totalNumber}}</span>
                </p>
                <p>
                  {{ $t('totalQuantity') }}：
                  <span>{{totalPics}}</span>
                </p>
              </div>
              <div class="price">
                {{ $t('totalPrice') }}：
                <span>
                  {{ userInfo.status === 1 ? userInfo.symbol + totalPrice : $t('viewPrice')
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <el-empty :description="$t('noData')" v-else></el-empty>
      </div>
    </div>
  </div>
</template>
<script>
import { getOrderDetail } from '@/api/order'
import orderBox from './orderBox'
import { mapGetters, mapState } from 'vuex'
export default {
  name: 'pageOrderDetail',
  components: {
    orderBox
  },
  data() {
    return {
      orderInfo: {}
    }
  },
  computed: {
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    }),
    // 总箱数
    totalNumber() {
      return this.orderInfo.orderDetails.reduce(
        (total, item) => total + item.number,
        0
      )
    },
    // 总数量
    totalPics() {
      return this.orderInfo.orderDetails.reduce(
        (total, item) => total + item.number * item.ou_Lo,
        0
      )
    },
    // 总价格
    totalPrice() {
      let totalPrice = 0
      if (this.orderInfo.orderDetails.length > 0) {
        this.orderInfo.orderDetails.forEach(item => {
          totalPrice += item.number * item.price * item.ou_Lo
        })
      }
      return totalPrice.toFixed(4)
    }
  },
  created() {
    this.initData()
  },
  methods: {
    // 初始化
    initData() {
      getOrderDetail({ orderNo: this.$route.query.orderNo })
        .then(res => {
          if (res.success) {
            this.orderInfo = res.data
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    },
    renderheader(h, { column }) {
      // renderheader函数得用到el-table-column上
      return h('span', {}, [
        h('span', {}, column.label.split('//')[0]), // 其中//也可以用其他符号替代
        h('br'),
        h('span', {}, column.label.split('//')[1])
      ])
    },
  }
}
</script>
<style lang="scss" scoped>
.company-profile-box {
  overflow: hidden;
  margin: 0 0 20px 0;

  .company-profile-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background: linear-gradient(0deg, #007bc3, #39adf6);
    border-radius: 10px 10px 0px 0px;
    line-height: 40px;
    font-size: 16px;
    color: #ffffff;
    padding: 0 20px;
    box-sizing: border-box;
    .my-order{
      cursor: pointer;
    }
  }
  .company-profile-info {
    min-height: 708px;
    background-color: #fff;
    border: 1px solid #0077c3;
    border-radius: 0px 0px 10px 10px;
    border-top: 0;
    padding: 20px;
    box-sizing: border-box;
    font-size: 12px;
    color: #828282;

    .order-detail-list {
      margin-top: 20px;

      .pro-img {
        width: 100px;
        height: 100px;

        img {
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }

      .price {
        color: #ff3e3e;
        font-weight: bold;
      }

      .action-list {
        display: flex;
        justify-content: flex-end;
        padding-top: 20px;

        .count {
          display: flex;
          align-content: center;

          p {
            margin-left: 20px;
            text-align: right;

            span {
              color: #ff3e3e;
              font-size: 18px;
              margin-left: 5px;
            }
          }
        }

        .price {
          margin-top: 20px;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          span {
            color: #ff3e3e;
            font-size: 24px;
            font-weight: 700;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>