<!--
 * @Description: 
 * @Author: zml
 * @Date: 2023-03-28 11:17:06
 * @LastEditors: zml
 * @LastEditTime: 2023-04-04 17:13:31
-->
<template>
  <div class="order-detail">
    <page-order-detail-tow v-if="cutTemplates === 'themeTemplate'"></page-order-detail-tow>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'orderDetailPage',
  mixins: [mixins]
}
</script>
<style lang="scss" scoped>
.order-detail {
  width: 100%;
}
</style>