<!--
 * @Description: 
 * @Author: pcc
 * @Date: 2022-12-07 08:59:40
 * @LastEditors: zml
 * @LastEditTime: 2023-04-03 18:36:47
-->
<template>
  <div class="pro-box">
    <page-pro-detail-tow v-if="cutTemplates === 'themeTemplate'"></page-pro-detail-tow>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'proDetailPage',
  mixins: [mixins],
  data() {
    return {}
  }
}
</script>
