<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-04-03 18:34:38
-->
<template>
  <div class="container product-box">
    <page-product-tow v-if="cutTemplates === 'themeTemplate'"></page-product-tow>
  </div>
</template>
<script>
import mixins from '@/utils/mixins'
export default {
  name: 'productPage',
  mixins: [mixins]
}
</script>
<style lang="scss" scoped>
.product-box {
  width: 100%;
  // background: #f1f3f6;
}
</style>