<template>
  <div class="nav-bar-box">
    <div class="container top-box">
      <div class="logo-box">
        <div class="left-box">
          <div class="img-box">
            <img :src="getSetting.logo" alt srcset />
          </div>
        </div>
        <div class="cneter-box">Welcome to Keli Star Toys & Gifts</div>
        <div class="right-box">
          <div class="img-box">
            <img src="../../../../assets/icon1.png" width="224" alt srcset />
          </div>
        </div>
      </div>
      <div class="nav-box">
        <div class="nav-item">
          <div @click="$router.push({ path: '/home' })">
            {{$t('KL.Nav.home')}}
            <img class="img-icon" src="../../../../assets/navLeft-icon.png" width="18" height="48" alt srcset />
          </div>
          <div @click="$router.push({ path: '/about' })">
            {{$t('KL.Nav.AboutUs')}}
            <img class="img-icon" src="../../../../assets/navLeft-icon.png" width="18" height="48" alt srcset />
          </div>
          <div @click="goProduct">
            {{$t('KL.Nav.Product')}}
            <img class="img-icon" src="../../../../assets/navLeft-icon.png" width="18" height="48" alt srcset />
          </div>
          <div @click="$router.push({ path: '/contact' })">
            {{$t('KL.Nav.ContactUs')}}
            <img class="img-icon" src="../../../../assets/navLeft-icon.png" width="18" height="48" alt srcset />
          </div>
          <div @click="goCart">
            {{$t('KL.Nav.Cart')}}
            <img class="img-icon" src="../../../../assets/navLeft-icon.png" width="18" height="48" alt srcset />
          </div>
          <div @click="goNews">
            {{$t('KL.news')}}
            <img class="img-icon" src="../../../../assets/navLeft-icon.png" width="18" height="48" alt srcset />
          </div>
        </div>
        <div class="language">
          <div class="zh" @click="changeLanguage('zh')">
            <div class="img-box">
              <img src="../../../../assets/zh.png" alt srcset />
            </div>中文>
          </div>

          <div class="en" @click="changeLanguage('en')">
            <div class="img-box">
              <img src="../../../../assets/en.png" alt srcset />
            </div>English>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { setDocumentTitle } from '@/utils/domUtil'
export default {
  name: 'navBar',

  data() {
    return {
      keywords: ''
    }
  },

  computed: {
    ...mapGetters('setting', {
      getSetting: 'get_setting'
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    })
  },
  methods: {
    ...mapActions('operate', {
      set_language: 'set_language'
    }),

    // 跳转产品
    goProduct() {
      if (this.$route.name === 'product') {
        this.$nextTick(() => {
          window.location.reload()
        })
      }
      this.$router.push({
        path: '/product'
      })
    },

    // 跳转购物车
    goCart() {
      if (!this.userInfo.id) {
        this.$message.warning(this.$t('KL.PleaseLoginFirst'))
        return
      }

      this.$router.push({ path: '/cart' })
    },

    // 跳转新闻
    goNews() {
      this.$router.push({ path: '/newsPage' })
    },

    // 语言切换
    changeLanguage(e) {
      if (e === 'en') {
        this.$i18n.locale = 'en'
        this.set_language('en')
        setDocumentTitle(this.getSetting.enTitle)
        // window.location.reload()
      } else if (e === 'zh') {
        this.$i18n.locale = 'zh'
        this.set_language('zh')
        setDocumentTitle(this.getSetting.title)
        // window.location.reload()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-bar-box {
  width: 100%;
  height: 168px;
  margin: 0 0 26px 0;
  // background-color: #fff;
  .top-box {
    // background-color: #eee;
    height: 100%;
    .logo-box {
      display: flex;
      justify-content: space-between;
      align-content: center;
      height: 120px;
      .right-box,
      .left-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 270px;
        .img-box {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 190px;
          height: 100px;
          img {
            max-width: 190px;
            max-height: 100px;
          }
        }
      }
      .cneter-box {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }

  .nav-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    background: linear-gradient(0deg, #007bc3, #1695e4);
    border-radius: 10px;

    .nav-item {
      flex: 1;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      & > div {
        position: relative;
        flex: 1;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        color: #ffffff;
        height: 100%;
        line-height: 48px;

        .img-icon {
          position: absolute;
          top: 0;
          right: -9px;
        }
      }
    }
    .language {
      display: flex;
      justify-content: space-around;
      width: 280px;
      margin: 0 0 0 20px;
      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 13px;
        font-weight: bold;
        cursor: pointer;
      }
      .zh {
        width: 100px;
        justify-content: flex-end;
        padding: 10px;
      }
      .en {
        flex: 1;
        justify-content: flex-start;
        padding: 10px;
      }

      .img-box {
        width: 32px;
        height: 32px;
        margin: 0 12px 0 0;

        img {
          max-width: 32px;
          max-height: 32px;
        }
      }
    }
  }
}
</style>
