<!--
 * @Description: 我的订单
 * @Author: pcc
 * @Date: 2022-11-02 17:20:32
 * @LastEditors: zml
 * @LastEditTime: 2023-04-04 15:18:16
-->
<template>
  <div class="container">
    <div class="company-profile-box">
      <div class="company-profile-title">
        <div>{{ $t('KL.MyOrder') }}</div>
        <div></div>
      </div>
      <div class="company-profile-info">
        <div class="order-list" v-if="orderList.length>0">
          <order-box v-for="(item, index) in orderList" :orderInfo="item" :key="index"></order-box>
          <div class="pagination">
            <el-pagination
              background
              :current-page="queryParam.pageNo"
              :page-size="queryParam.pageSize"
              :page-sizes="[10, 20, 30, 40]"
              :total="totalRows"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            ></el-pagination>
          </div>
        </div>
        <el-empty :description="$t('noData')" v-else></el-empty>
      </div>
    </div>
  </div>
</template>
<script>
import { getOrderList } from '@/api/order'
import { mapGetters } from 'vuex'
import orderBox from './orderBox.vue'
export default {
  name: 'pageOrder',
  components: {
    orderBox
  },
  data() {
    return {
      orderList: [],
      queryParam: {
        pageNo: 1,
        pageSize: 10,
        sortField: '',
        sortOrder: '',
        keywords: '',
        status: -1
      },
      totalRows: 0
    }
  },
  computed: {
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    })
  },
  created() {
    this.initData()
  },
  methods: {
    // 初始化
    initData() {
      this.queryParam.userAccount = this.userInfo.userAccount
      getOrderList(this.queryParam).then(res => {
        this.orderList = res.data.rows
        this.totalRows = res.data.totalRows
      })
    },
    // 选择页码大小
    handleSizeChange(pageSize) {
      this.queryParam.pageSize = pageSize
      this.initData()
    },
    // 分页
    handleCurrentChange(pageNo) {
      this.queryParam.pageNo = pageNo
      this.initData()
    }
  }
}
</script>
<style lang="scss" scoped>
.company-profile-box {
  overflow: hidden;
  margin: 0 0 20px 0;

  .company-profile-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background: linear-gradient(0deg, #007bc3, #39adf6);
    border-radius: 10px 10px 0px 0px;
    line-height: 40px;
    font-size: 16px;
    color: #ffffff;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .company-profile-info {
    min-height: 708px;
    background-color: #fff;
    border: 1px solid #0077c3;
    border-radius: 0px 0px 10px 10px;
    border-top: 0;
    padding: 20px;
    box-sizing: border-box;
    font-size: 12px;
    color: #828282;

    .order-list {
      width: 100%;

      .pagination {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        margin-top: 15px;
      }
    }
  }
}
</style>