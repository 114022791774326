<template>
  <div>
    <div class="container">
      <div class="company-profile-box">
        <div class="company-profile-title">
          <div>{{$t('KL.newsDetail')}}</div>
          <div></div>
        </div>
        <div class="company-profile-info">
          <div class="img-box">
            <img :src="newDetailData.imgUrl" alt srcset />
          </div>
          <div class="content">
            <div class="news-title">
              <div class="tit">{{ $hs(newDetailData.title,newDetailData.enTitle) }}</div>
              <div class="date">{{$t('KL.releaseTime')}}：{{ newDetailData.createdTime }}</div>
            </div>
            <div class="parse-html" v-html="$hs(newDetailData.content,newDetailData.enContent)||''"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetDetail } from '@/api/news'
import { mapState } from 'vuex'
export default {
  name: 'pageDetail',
  data() {
    return {
      newDetailData: {}
    }
  },
  computed: {
    ...mapState('operate', ['language'])
  },
  created() {
    this.queryPage()
  },
  methods: {
    queryPage() {
      // 渲染html
      GetDetail({ id: this.$route.query.num })
        .then(res => {
          if (res.success) {
            this.newDetailData = res.data || {}
            // let date = ''
            // date = new Date(this.newDetailData.createdTime)
            // let year = date.getFullYear()
            // let month = date.getMonth() + 1
            // let d = date.getDate()
            // this.newDetailData.createdTime = year + '-' + month + '-' + d
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(err => {
          this.$message.error(err)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.company-profile-box {
  overflow: hidden;
  margin: 0 0 20px 0;

  .company-profile-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background: linear-gradient(0deg, #007bc3, #39adf6);
    border-radius: 10px 10px 0px 0px;
    line-height: 40px;
    font-size: 16px;
    color: #ffffff;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .company-profile-info {
    min-height: 708px;
    background-color: #fff;
    border: 1px solid #0077c3;
    border-radius: 0px 0px 10px 10px;
    border-top: 0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;
    color: #828282;
    .img-box {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      height: 340px;
      width: 100%;
      padding: 0 0 10px 0;
      border-bottom: 1px solid #eee;
      img {
        max-width: 1000px;
        max-height: 340px;
      }
    }

    .content {
      padding: 0 50px;
      .news-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0 0 0;
        .tit {
          height: 50px;
          font-size: 24px;
          color: #222222;
        }
        .date {
          font-size: 14px;
          color: #666666;
          margin: 0 0 20px 0;
        }
      }
      :deep .parse-html{
        img{
          display: flex;
          margin: auto;
          max-width: 1000px !important;
        }
      }
    }
  }
}
</style>
