<!--
 * @Description: 新闻中心
 * @Author: zml
 * @Date: 2023-03-28 17:35:27
 * @LastEditors: zml
 * @LastEditTime: 2023-05-05 17:45:54
-->
<template>
  <div>
    <div class="container">
      <div class="company-profile-box">
        <div class="company-profile-title">
          <div>{{$t('KL.news')}}</div>
          <div></div>
        </div>
        <div class="company-profile-info">
          <div class="items" v-for="(item,index) in newsData" :key="index">
            <div class="left" @click="$router.push({path:'/newDetail',query:{num: item.id}})">
              <img :src="item.imgUrl" alt srcset />
            </div>
            <div class="right">
              <div class="content-title">
                <span
                  style="cursor: pointer;color: #222222;"
                  @click="$router.push({path:'/newDetail',query:{num: item.id}})"
                >{{ $hs(item.title,item.enTitle) }}</span>
              </div>
              <div class="content">{{$hs(item.description,item.enDescription)||''}}</div>
              <div class="date">{{item.createdTime }}</div>
            </div>
          </div>
          <div class="pagination-box" v-if="newsData.length > 0">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="queryParam.pageNo"
              :page-sizes="[5, 10, 15, 20,25]"
              :page-size="queryParam.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalRows"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { QueryPage } from '@/api/news'
import { mapState } from 'vuex'
export default {
  name: 'pageDetail',
  data() {
    return {
      queryParam: {
        pageNo: 1,
        pageSize: 10,
        sortField: '',
        sortOrder: '',
        keywords: ''
      },
      totalRows: 0,
      totalPage: 0,
      newsData: []
    }
  },
  computed: {
    ...mapState('operate', ['language'])
  },
  created() {
    this.queryPage()
  },
  methods: {
    queryPage() {
      QueryPage(this.queryParam)
        .then(res => {
          if (res.success) {
            this.newsData = res.data.rows || []
            this.totalRows = res.data.totalRows
            this.totalPage = res.data.totalPage
            // this.newsData.forEach(item => {
            //   let date = ''
            //   date = new Date(item.createdTime)
            //   let year = date.getFullYear()
            //   let month = date.getMonth() + 1
            //   let day = date.getDate()
            //   item.createdTime = year + '/' + month + '/' + day
            // })
          }
        })
        .catch(err => {
          this.$message.error(err.message)
        })
    },
    // 分页大小操作
    handleSizeChange(pageSize) {
      this.queryParam.pageNo = 1
      this.queryParam.pageSize = pageSize
      this.queryPage()
    },
    // 分页翻页操作
    handleCurrentChange(pageNo) {
      this.queryParam.pageNo = pageNo
      this.queryPage()
    }
  }
}
</script>
<style lang="scss" scoped>
.company-profile-box {
  overflow: hidden;
  margin: 0 0 20px 0;

  .company-profile-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    background: linear-gradient(0deg, #007bc3, #39adf6);
    border-radius: 10px 10px 0px 0px;
    line-height: 40px;
    font-size: 16px;
    color: #ffffff;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .company-profile-info {
    min-height: 708px;
    background-color: #fff;
    border: 1px solid #0077c3;
    border-radius: 0px 0px 10px 10px;
    border-top: 0;
    padding: 10px;
    box-sizing: border-box;
    font-size: 12px;
    color: #828282;
    .items {
      display: flex;
      height: 200px;
      margin-bottom: 30px;
      // background-color: #eee;
      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 400px;
        height: 200px;
        border-radius: 5px;
        box-shadow: 0 0 6px 2px #f5f5f5;
        overflow: hidden;
        cursor: pointer;
        &:hover {
          box-shadow: 0 0 10px 6px #eee;
        }
        img {
          max-width: 400px;
          max-height: 200px;
        }
      }
      .right {
        flex: 1;
        font-size: 16px;
        padding: 10px 20px;
        .content-title {
          margin: 0 0 10px 0;
          font-weight: bold;
        }
        .content {
          line-height: 30px;
          width: 700px;
          color: #222222;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          text-overflow: ellipsis;
        }
        .date {
          font-size: 14px;
          color: #666666;
          margin: 10px 0 0;
        }
      }
    }

    .pagination-box {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
