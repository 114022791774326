<!--
 * @Description: 
 * @Author: zml
 * @Date: 2022-10-27 09:06:05
 * @LastEditors: zml
 * @LastEditTime: 2023-04-03 18:40:48
-->
<template>
  <div>
    <about-detail-tow v-if="cutTemplates === 'themeTemplate'" :datas="aboutData"></about-detail-tow>
  </div>
</template>
<script>
import { getCompanyInfo } from '@/api/home'
import mixins from '@/utils/mixins'
export default {
  name: 'aboutPage',
  mixins: [mixins],
  data() {
    return {
      aboutData: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      // 获取公司简介
      let _this = this
      getCompanyInfo().then(res => {
        if (res.success) {
          _this.aboutData = res.data
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
</style>