<!--
 * @Description: 首页
 * @Author: zml
 * @Date: 2023-03-28 11:17:06
 * @LastEditors: zml
 * @LastEditTime: 2023-05-19 12:28:23
-->
<template>
  <div class="container home">
    <div class="left-box">
      <div class="contact-us">
        <div class="contact-title">{{$t('KL.CardTit.ContactUs')}}</div>
        <div class="contact-info">
          <div>
            <span style="font-weight:bold">{{$t('companyTel')}}: </span>
            <span>{{getSetting.telPhone}}</span>
          </div>
          <div>
            <span style="font-weight:bold">{{$t('KL.mobile')}}: </span>
            <span>{{getSetting.mobile}}</span>
          </div>
          <div>
            <span style="font-weight:bold">{{$t('KL.Wechat')}}: </span>
            <span>{{getSetting.weChatNo}}</span>
          </div>
          <div>
            <span style="font-weight:bold">QQ: </span>
            <span>{{getSetting.qq}}</span>
          </div>
          <div>
            <span style="font-weight:bold">{{$t('KL.Email')}}: </span>
            <span>{{getSetting.homeMail}}</span>
          </div>
        </div>
      </div>
      <div class="search-box">
        <el-input :placeholder="$t('KL.placeholderProductKey')" v-model="productInputValue">
          <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearchProduct"></i>
        </el-input>
      </div>
      <div class="member-box">
        <div class="member-title">{{$t('KL.CardTit.MemberArea')}}</div>
        <div class="member-info">
          <div v-if="!userInfo.id">
            <el-form label-position="top" label-width="80px" ref="loginForm" :model="loginForm" :rules="loginRules">
              <el-form-item :label="$t('KL.MemberNumber')+'：'" required prop="userAccount">
                <el-input v-model="loginForm.userAccount" :placeholder="$t('KL.pleaseMemberNumber')"></el-input>
              </el-form-item>
              <el-form-item :label="$t('KL.Password')+'：'" required prop="userPassword">
                <el-input
                  type="password"
                  autocomplete="new-password"
                  v-model="loginForm.userPassword"
                  :placeholder="$t('KL.PleaseEnterPassword')"
                ></el-input>
              </el-form-item>
            </el-form>
            <div class="btn">
              <div class="login" @click="onLogin">{{$t('KL.Login')}}</div>
              <div class="sign" @click="onSign">{{$t('KL.Register')}}</div>
            </div>
          </div>
          <div v-else>
            <div class="login-success">{{$t('KL.Hello')}}，{{userInfo.userAccount}}</div>
            <div class="login-success order-btn" @click="onMyOrder">{{$t('KL.MyOrder')}}</div>
            <div class="login-success out-btn" @click="onLogOut">{{$t('KL.logOut')}}</div>
          </div>
        </div>
      </div>
      <div class="classify-box">
        <div class="classify-title">{{$t('KL.CardTit.Category')}}</div>
        <div class="classify-info">
          <el-menu default-active="2" class="el-menu-vertical-demo" :default-openeds="openeds">
            <el-submenu v-for="(item,index) in setMenus" :key="item.id + 'index'" :index="`${index}`">
              <template slot="title">
                <div>
                  <span @click="handleClose(item)">
                    <i class="icon"></i>
                    <span>{{$hs(item.cl_Na,item.cl_Ne_Na,item.threeLanguage)||''}}</span>
                  </span>
                  <i v-if="item.children.length>0" class="el-icon-arrow-down"></i>
                </div>
              </template>
              <el-menu-item-group>
                <el-menu-item
                  v-for="it in item.children"
                  @click="handleClose(it)"
                  :key="it.id + 'children'"
                  :index="item.id+'-'+it.id"
                >{{$hs(it.cl_Na,it.cl_Ne_Na,it.threeLanguage)||''}}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="slider-box">
        <el-carousel height="220px" :loop="true">
          <el-carousel-item v-for="item in swiperList" :key="item.id">
            <div class="img-box">
              <img :src="item.imgUrl" alt srcset />
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="new-product-box">
        <div class="new-product-title">
          <div style="display: flex;align-items:center">
            <img src="../../../../assets/KLXnew.png" width="20" alt srcset />
            <span style="margin: 0 0 0 8px;">{{$t('KL.CardTit.latestProduct')}}</span>
          </div>
          <div style="cursor: pointer;" @click="onNewMore">{{$t('KL.More')}}+</div>
        </div>
        <div class="new-product-info">
          <div class="carousel-container" @mouseenter="stopAnimation" @mouseleave="startAnimation">
            <div class="carousel-wrapper" ref="wrapper">
              <div class="carousel-item" v-for="(item, index) in newProductList" :key="index">
                <div class="img-box" @click="handleClick(item)">
                  <img :src="item.imgUrl" id="img" alt="CC5521631" srcset />
                </div>
                <div class="describe">
                  <div>{{ item.co_Nu }}</div>
                  <div class="name">{{ $hs(item.pr_Na,item.pr_En_Na,item.basicThreeLanguage) || '' }}</div>
                </div>
              </div>
              <div class="carousel-item" v-for="(item, index) in newProductList" :key="`${index}-copy`">
                <div class="img-box" @click="handleClick(item)">
                  <img :src="item.imgUrl" id="img" alt="CC5521631" srcset />
                </div>
                <div class="describe">
                  <div>{{ item.co_Nu }}</div>
                  <div class="name">{{ $hs(item.pr_Na,item.pr_En_Na,item.basicThreeLanguage) || '' }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hot-box">
        <div class="hot-title">
          <div style="display: flex;align-items:center;margin:0 0 0 -3px">
            <img src="../../../../assets/KLXhot.png" width="24" alt srcset />
            <span style="margin: 0 0 0 6px;">{{$t('KL.CardTit.HotProduct')}}</span>
          </div>
          <div style="cursor: pointer;" @click="onHotMore">{{$t('KL.More')}}+</div>
        </div>
        <div class="hot-info">
          <div class="hot-item" v-for="item in hotProductsLsit" :key="item.id">
            <div class="img-box" @click="$router.push('/proDetail?coNu='+ item.co_Nu)">
              <img :src="item.imgUrl" alt srcset />
            </div>
            <div class="hot-describe">
              <div>
                <div>{{item.co_Nu}}</div>
                <div class="name">{{$hs(item.pr_Na,item.pr_En_Na,item.basicThreeLanguage)||''}}</div>
              </div>
              <div class="add-cart" @click="onAddCart(item)">
                <div class="add">{{$t('KL.addCart')}}</div>
                <div class="cart-icon">
                  <img src="../../../../assets/KLXcart.png" alt width="18" srcset />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="height-search-box">
        <div class="height-search-title">
          <div style="display: flex;align-items:center;">
            <img src="../../../../assets/KLXsearch.png" width="18" alt srcset />
            <span style="margin: 0 0 0 8px;">{{$t('KL.CardTit.AdvancedSearch')}}</span>
          </div>
          <div></div>
        </div>
        <div class="height-search-info">
          <div class="left">
            <div class="form-title">{{$t('KL.SearchByDescription')}}</div>
            <div class="form-box">
              <div class="form-info">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="66px" class="demo-ruleForm">
                  <el-form-item :label="$t('KL.Date') +'：'">
                    <el-col :span="11">
                      <el-form-item prop="date1">
                        <el-date-picker type="date" size="mini" value-format="yyyy-MM-dd" v-model="ruleForm.date1" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" style="text-align:center" :span="2">{{$t('KL.to')}}</el-col>
                    <el-col :span="11">
                      <el-form-item prop="date2">
                        <el-date-picker type="date" size="mini" value-format="yyyy-MM-dd" v-model="ruleForm.date2" style="width: 100%;"></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                  <el-form-item :label="$t('KL.describe')+'：'" prop="remark">
                    <el-input size="mini" v-model="ruleForm.remark"></el-input>
                  </el-form-item>
                  <el-form-item :label="$t('KL.kind')+'：'" prop="cl_Nu">
                    <el-cascader
                      :options="options"
                      style="width: 100%;"
                      v-model="ruleForm.cl_Nu"
                      size="mini"
                      :placeholder="$t('KL.PleaseSelect')"
                      :show-all-levels="false"
                      @visible-change="visibleChange"
                      clearable
                    ></el-cascader>
                  </el-form-item>
                  <el-form-item :label="$t('KL.price')+'：'">
                    <el-col :span="11">
                      <el-form-item prop="price1">
                        <el-input type="text" size="mini" v-model="ruleForm.price1"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" style="text-align:center" :span="2">{{$t('KL.to')}}</el-col>
                    <el-col :span="11">
                      <el-form-item prop="price2">
                        <el-input type="text" size="mini" v-model="ruleForm.price2"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-form-item>
                  <el-form-item :label="$t('KL.size')+'：'">
                    <el-col class="line" style="text-align:center" :span="2">{{$t('KL.L')}}</el-col>
                    <el-col :span="5">
                      <el-form-item prop="pr_Le">
                        <el-input type="text" size="mini" v-model="ruleForm.pr_Le"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" style="text-align:center" :span="2">{{$t('KL.W')}}</el-col>
                    <el-col :span="5">
                      <el-form-item prop="pr_Wi">
                        <el-input type="text" size="mini" v-model="ruleForm.pr_Wi"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" style="text-align:center" :span="2">{{$t('KL.H')}}</el-col>
                    <el-col :span="5">
                      <el-form-item prop="pr_Hi">
                        <el-input type="text" size="mini" v-model="ruleForm.pr_Hi"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col class="line" style="text-align:center" :span="3">(±1cm)</el-col>
                  </el-form-item>
                  <el-form-item :label="$t('KL.Orders')+'：'" prop="sort">
                    <el-radio-group v-model="ruleForm.sort">
                      <el-radio label="cl_Nu">{{$t('KL.kind')}}</el-radio>
                      <el-radio label="price">{{$t('KL.price')}}</el-radio>
                      <el-radio label="createdTime">{{$t('KL.Date')}}</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
              </div>
              <div class="form-btn">
                <div class="search" @click="submitForm('ruleForm')">{{$t('KL.Search')}}</div>
                <div class="reset" @click="resetForm('ruleForm')">{{$t('KL.Reset')}}</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="more-title">{{$t('KL.MultipleSearch')}}</div>
            <div class="more-box">
              <div>
                <el-input
                  type="textarea"
                  style="height: 214px;"
                  v-model="textareaValue"
                  :autosize="{ minRows: 9, maxRows: 9}"
                  :placeholder="`${$t('KL.MultipleSearchText')}
HS000001
HS000002
HS000003`"
                ></el-input>
              </div>
              <div class="more-btn">
                <div class="search" @click="moreProductNo">{{$t('KL.Search')}}</div>
                <div class="reset" @click="textareaValue=''">{{$t('KL.Reset')}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <reg-modal ref="regModal"></reg-modal>
  </div>
</template>
<script>
import {
  CarouselGetList,
  GetProductListByDiscount,
  ProductClassWebGetTree,
  getCompanyInfo,
  GetContactInfo
} from '@/api/home'
import { queryPage } from '@/api/product'
import { LoginMemberNoCode } from '@/api/login'
import { addCartsApi } from '@/api/cart'
import { mapState, mapGetters, mapActions } from 'vuex'
import regModal from '@/components/themeTemplate/pc_components/memberModal/regModal.vue'
import transformMark from '@/utils/water'
export default {
  name: 'pageHome',
  components: {
    regModal
  },

  props: {},

  data() {
    return {
      productInputValue: '',
      loginForm: {
        userAccount: '',
        userPassword: ''
      },
      openeds: [],
      loginRules: {},
      options: [],
      textareaValue: '',
      ruleForm: {
        date1: '',
        date2: '',
        remark: '',
        cl_Nu: [],
        price1: '',
        price2: '',
        pr_Le: '',
        pr_Wi: '',
        pr_Hi: '',
        sort: ''
      },
      rules: {},

      //轮播图数据
      swiperList: [],
      // 新产品数据
      newProductList: [],
      // 产品分类列表
      setMenus: [],
      // 热销产品列表
      hotProductsLsit: [],
      // 和我们联系
      contactUsData: {},
      // 公司简介
      aboutDataList: {},

      queryParam: {
        pageNo: 1,
        pageSize: 10,
        sortField: '',
        sortOrder: '',
        cl_Nu: '',
        isAnd: false,
        conditionals: [],
        startTime: null
      }
    }
  },
  popos: {},
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapGetters('setting', {
      getSetting: 'get_setting',
      getMenus: 'get_menus'
    })
  },
  created() {
    this.initMenus()
  },
  mounted() {
    this.initData()
    this.gettingData()
    this.startAnimation()
  },
  methods: {
    ...mapActions('user', ['set_userInfo', 'logout']),
    startAnimation() {
      this.$nextTick(() => {
        if (this.$refs.wrapper) {
          this.$refs.wrapper.style.animationPlayState = 'running'
        }
      })
    },

    stopAnimation() {
      this.$nextTick(() => {
        if (this.$refs.wrapper) {
          this.$refs.wrapper.style.animationPlayState = 'paused'
        }
      })
    },

    handleClick(item) {
      this.$router.push({
        path: 'proDetail',
        query: {
          coNu: item.co_Nu
        }
      })
    },
    onSearchProduct() {
      this.$router.push({
        path: '/product'
      })
      this.$bus.$emit('searchProduct', this.productInputValue)
      sessionStorage.setItem('searchProduct',this.productInputValue)
    },
    // 跳转新品
    onNewMore() {
      this.$router.push({
        path: '/product'
      })
    },

    // 跳转热销
    onHotMore() {
      this.$router.push({
        path: '/product',
        query: {
          discount: 'isDiscount',
          Hot: 1
        }
      })
    },

    onAddCart(item) {
      if (!this.userInfo.id) {
        this.$message.warning(this.$t('KL.PleaseLoginBeforeAdding'))
        return
      }
      let obj = {
        client_Nu: this.userInfo.client_Nu || '',
        userId: this.userInfo.id,
        co_Nu: item.co_Nu,
        number: 1
      }
      addCartsApi(obj).then(res => {
        if (res.success) {
          this.$message.success(this.$t('KL.AddSuccess'))
        } else {
          this.$message.error(this.$t('KL.AddError') + res.message)
        }
      })
    },

    handleClose(item) {
      this.$router.push({
        path: '/product',
        query: {
          clNu: item.cl_Nu,
          cl_Na: item.cl_Na,
          cl_Ne_Na: item.cl_Ne_Na,
          threeLanguage: item.threeLanguage
        }
      })
    },
    submitForm(formName) {
      let obj = {}
      this.ruleForm.cl_Nu = this.ruleForm.cl_Nu[0]
      for (const key in this.ruleForm) {
        if (this.ruleForm[key]) {
          obj[key] = this.ruleForm[key]
        }
      }
      if (Object.keys(obj).length === 0) {
        this.$message.warning(this.$t('KL.PleaseEnterSearchCriteria'))
        this.ruleForm.cl_Nu = []
        return
      }
      this.$router.push('/product')
      this.$bus.$emit('advancedSearch', obj)
    },

    moreProductNo() {
      if (!this.textareaValue) {
        this.$message.warning(this.$t('KL.PleaseEnterSearchCriteria'))
        return
      }
      this.$router.push({
        path: '/product',
        query: {
          coNus: this.textareaValue
        }
      })
    },

    // 登录
    onLogin() {
      const _this = this
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          LoginMemberNoCode(this.loginForm)
            .then(res => {
              if (res.success) {
                this.$message.success(_this.$t('KL.LoginSuccess'))

                _this.set_userInfo(res.data)

                window.location.reload()
              } else {
                _this.$message.error(_this.$t('KL.LoginError') + res)
              }
            })
            .catch(err => {
              _this.$message.error(_this.$t('KL.LoginError') + err)
            })
        } else {
          return false
        }
      })
    },
    onLogOut() {
      const _this = this
      this.$confirm(_this.$t('KL.isLogOut'), this.$t('KL.SystemPrompt'), {
        confirmButtonText: _this.$t('KL.Confirm'),
        cancelButtonText: _this.$t('KL.Cancel'),
        type: 'warning'
      })
        .then(() => {
          _this.logout()
          window.location.reload()
        })
        .catch(() => {})
    },

    onMyOrder() {
      this.$router.push({
        path: '/order'
      })
    },

    // 注册
    onSign() {
      // 打开注册模块
      this.$refs.regModal.open()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    // 热销产品
    async gettingData() {
      let _this = this
      const parameters = {
        client_Nu: _this.userInfo.client_Nu || '',
        num: 10,
        category: 1
      }
      const result = await GetProductListByDiscount(parameters) // 热销产品
      if (result.success) {
        this.hotProductsLsit = result.data || []
        this.hotProductsLsit.forEach(item => {
          transformMark(item.imgUrl).then(result => {
            item.imgUrl = result
          })
        })
      } else {
        this.$message.error(result.message)
      }
    },
    initData() {
      let _this = this
      // 首页轮播
      CarouselGetList()
        .then(res => {
          if (res.success) {
            _this.swiperList = res.data
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch(err => {
          _this.$message.error(err)
        })

      // 获取最新产品
      queryPage(this.queryParam)
        .then(res => {
          if (res.success) {
            this.newProductList = res.data.rows || []
            this.newProductList.forEach(item => {
              transformMark(item.imgUrl).then(result => {
                item.imgUrl = result
              })
            })
          } else {
            this.$message.error(res.message)
          }
        })
        .catch(err => {
          _this.$message.error(err)
        })

      // 公司简介
      getCompanyInfo()
        .then(res => {
          if (res.success) {
            _this.aboutDataList = res.data
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch(err => {
          _this.$message.error(err)
        })

      // 和我们联系
      GetContactInfo()
        .then(res => {
          if (res.success) {
            _this.contactUsData = res.data
          } else {
            _this.$message.error(res.message)
          }
        })
        .catch(err => {
          _this.$message.error(err)
        })
    },
    visibleChange(e) {
      if (e) {
        this.optionFun()
      }
    },
    // 获取种类列表
    initMenus() {
      ProductClassWebGetTree()
        .then(res => {
          if (res.success) {
            this.setMenus = res.data

            this.openeds = res.data.map((item, index) => {
              return String(index)
            })
            this.optionFun()
          } else {
            this.$message(res.message)
          }
        })
        .catch(err => {
          this.$message({ err })
        })
    },
    optionFun() {
      this.options = this.setMenus.map(item => {
        let children = []
        if (item.children.length > 0) {
          children = item.children.map(it => {
            return {
              value: it.cl_Nu,
              label: this.$hs(it.cl_Na, it.cl_Ne_Na, it.threeLanguage)
            }
          })
        }
        if (children.length > 0) {
          return {
            value: item.cl_Nu,
            label: this.$hs(item.cl_Na, item.cl_Ne_Na, item.threeLanguage),
            children: children
          }
        } else {
          return {
            value: item.cl_Nu,
            label: this.$hs(item.cl_Na, item.cl_Ne_Na, item.threeLanguage)
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.home {
  display: flex;
  .left-box {
    width: 180px;
    margin: 0 20px 0 0;
    .contact-us {
      overflow: hidden;
      margin: 0 0 20px 0;

      .contact-title {
        height: 40px;
        background: linear-gradient(0deg, #007bc3, #39adf6);
        border-radius: 10px 10px 0px 0px;
        line-height: 40px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 10px;
        box-sizing: border-box;
      }
      .contact-info {
        height: 180px;
        background-color: #fff;
        border: 1px solid #0077c3;
        border-radius: 0px 0px 10px 10px;
        border-top: 0;
        padding: 10px 6px;
        box-sizing: border-box;
        font-size: 12px;
        color: #828282;
        & > div {
          margin-bottom: 10px;
        }
      }
    }
    .search-box {
      margin: 0 0 10px 0;
      :deep input {
        height: 44px;
      }
      .el-input__icon {
        font-size: 18px;
        cursor: pointer;
        &:hover {
          font-size: 24px;
        }
      }
    }
    .member-box {
      overflow: hidden;
      margin: 0 0 20px 0;

      .member-title {
        height: 40px;
        background: linear-gradient(0deg, #007bc3, #39adf6);
        border-radius: 10px 10px 0px 0px;
        line-height: 40px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 10px;
        // text-align: center;
        box-sizing: border-box;
      }
      .member-info {
        height: 171px;
        background-color: #fff;
        border: 1px solid #0077c3;
        border-radius: 0px 0px 10px 10px;
        border-top: 0;
        padding: 10px;
        box-sizing: border-box;
        font-size: 12px;
        color: #828282;
        :deep(.el-form-item) {
          margin: 0 !important;
          .el-form-item__label {
            line-height: normal;
            padding: 0;
          }
          .el-input__inner {
            height: 28px;
          }
          .el-form-item__error {
            display: none;
          }
        }

        .login-success {
          height: 28px;
          line-height: 30px;
          border-bottom: 1px solid #eee;
          font-size: 14px;
          margin: 0 0 20px 0;
          padding: 0 10px;
          &.order-btn,
          &.out-btn {
            cursor: pointer;
            &:hover {
              background-color: #eee;
              border-radius: 2px;
              color: #289ee7;
            }
          }
        }
        .btn {
          display: flex;
          justify-content: space-around;
          margin-top: 6px;
          & > div {
            width: 68px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            border-radius: 12px;
            cursor: pointer;
          }
          .login {
            background-color: #00a9b4;
          }
          .sign {
            background-color: #3e97d1;
          }
        }
      }
    }
    .classify-box {
      overflow: hidden;
      margin: 0 0 20px 0;

      .classify-title {
        height: 40px;
        background: linear-gradient(0deg, #007bc3, #39adf6);
        border-radius: 10px 10px 0px 0px;
        line-height: 40px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 10px;
        box-sizing: border-box;
      }
      .classify-info {
        height: 914px;
        background-color: #fff;
        border: 1px solid #0077c3;
        border-radius: 0px 0px 10px 10px;
        border-top: 0;
        padding: 10px;
        box-sizing: border-box;
        font-size: 12px;
        color: #828282;
        overflow: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 5px;
          // height: 5px;
        }
        /* 滚动条上的滚动滑块 */
        &::-webkit-scrollbar-thumb {
          background-color: #49b1f5;
          border-radius: 32px;
        }
        /* 滚动条轨道 */
        &::-webkit-scrollbar-track {
          background-color: #dbeffd;
          border-radius: 32px;
        }

        :deep(.el-menu-vertical-demo) {
          border-right: 0px !important;

          .el-submenu__title {
            padding: 0 !important;
            height: 32px;
            line-height: 30px;
            color: #000;
            border-radius: 6px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            & > div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              &:hover {
                & > i {
                  color: #fff;
                  font-weight: bold;
                  background-color: #0f88d0;
                }
              }
              & > span {
                width: 140px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
              & > i {
                flex: 1;
                display: inline-block;
                margin: 0;
                font-size: 16px;
                line-height: 32px;
                &:hover {
                  color: #fff;
                  font-weight: bold;
                  background-color: #0f88d0;
                }
              }
            }

            i.icon {
              display: inline-block;
              width: 6px;
              height: 6px;
              background: #444;
              border-radius: 50%;
              margin: 0 6px 0 6px;
            }
            span {
              font-size: 15px !important;
            }
          }

          .el-menu-item {
            min-width: 100%;
            padding: 0 18px !important;
            height: 32px;
            line-height: 30px;
            border-radius: 6px;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .el-menu-item-group__title {
            padding: 0 !important;
          }
          .el-submenu__icon-arrow {
            display: none;
          }
        }
      }
    }
  }

  .right-box {
    flex: 1;
    // background-color: #aaa;
    .slider-box {
      height: 220px;
      margin: 0 0 20px 0;
      // background-color: pink;
      border: 1px solid #0077c3;
      border-radius: 10px;
      overflow: hidden;
      .img-box {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
    .new-product-box {
      overflow: hidden;
      margin: 0 0 20px 0;

      .new-product-title {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        background: linear-gradient(0deg, #007bc3, #39adf6);
        border-radius: 10px 10px 0px 0px;
        line-height: 40px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .new-product-info {
        width: 1000px;
        height: 220px;
        background-color: #fff;
        border: 1px solid #0077c3;
        border-radius: 0px 0px 10px 10px;
        border-top: 0;
        padding: 10px 0 0 0;
        box-sizing: border-box;
        font-size: 12px;
        color: #828282;
        overflow: hidden;
        position: relative;

        .carousel-container {
          position: relative;
          width: 1000px;
          height: 196px;
          overflow: hidden;
        }

        .carousel-wrapper {
          display: flex;
          align-items: center;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          white-space: nowrap;
          animation: carousel 40s linear infinite;
        }

        .carousel-item {
          width: 180px;
          height: 190px;
          margin-right: 15px;
          border: 1px solid #d6d6d6;
          display: inline-flex;
          flex-direction: column;
          font-size: 24px;
          color: #fff;
          box-sizing: border-box;
          overflow: hidden;

          .img-box {
            width: 178px;
            height: 135px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            border-bottom: 1px solid #f7f7f7;
            overflow: hidden;
            cursor: pointer;
            &:hover {
              img {
                transition-duration: 0.5s;
                transform: scale(1.3);
              }
            }
            img {
              max-width: 100%;
              max-height: 135px;
              transition-duration: 0.5s;
            }
          }

          .describe {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            color: #222222;
            .name {
              width: 160px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
            }
          }
        }

        @keyframes carousel {
          0% {
            transform: translateX(0);
          }

          100% {
            transform: translateX(-50%);
          }
        }
      }
    }
    .hot-box {
      overflow: hidden;
      margin: 0 0 20px 0;

      .hot-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        background: linear-gradient(0deg, #007bc3, #39adf6);
        border-radius: 10px 10px 0px 0px;
        line-height: 40px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .hot-info {
        display: flex;
        flex-flow: wrap;
        width: 1000px;
        height: 520px;
        background-color: #fff;
        border: 1px solid #0077c3;
        border-radius: 0px 0px 10px 10px;
        border-top: 0;
        padding: 20px;
        box-sizing: border-box;
        font-size: 12px;
        color: #828282;

        .hot-item {
          width: 180px;
          height: 233px;
          // background-color: #eee;
          margin: 0 14px 20px 0;
          border: 1px solid #d6d6d6;
          box-sizing: border-box;
          overflow: hidden;
          &:nth-child(5n) {
            margin-right: 0;
          }
          .img-box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 178px;
            height: 135px;
            overflow: hidden;
            cursor: pointer;
            &:hover {
              img {
                transition-duration: 0.5s;
                transform: scale(1.3);
              }
            }

            img {
              max-width: 100%;
              max-height: 135px;
              transition-duration: 0.5s;
            }
          }
          .hot-describe {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 70px;
            margin: 10px 0 0 0;
            & > div {
              text-align: center;
            }

            .name {
              width: 160px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: center;
            }
            .add-cart {
              display: flex;
              align-items: center;
              width: 102px;
              height: 28px;
              cursor: pointer;
              .add {
                flex: 1;
                height: 100%;
                line-height: 28px;
                font-size: 12px;
                color: #ffffff;
                text-shadow: 0.5px 0.87px 2px 0px rgba(0, 0, 0, 0.2);
                background-color: #0985d0;
              }
              .cart-icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 28px;
                height: 100%;
                line-height: 28px;
                background-color: #ffc000;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
    .height-search-box {
      overflow: hidden;
      margin: 0 0 20px 0;

      .height-search-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 44px;
        background: linear-gradient(0deg, #007bc3, #39adf6);
        border-radius: 10px 10px 0px 0px;
        line-height: 40px;
        font-size: 16px;
        color: #ffffff;
        padding: 0 20px;
        box-sizing: border-box;
      }
      .height-search-info {
        display: flex;

        height: 325px;
        background-color: #fff;
        border: 1px solid #0077c3;
        border-radius: 0px 0px 10px 10px;
        border-top: 0;
        padding: 10px 20px;
        box-sizing: border-box;
        font-size: 13px;
        color: #828282;

        .left {
          width: 520px;
          margin: 0 44px 0 0;
          .form-title {
            height: 30px;
            // line-height: 34px;
            padding: 6px 0 0 0;
            color: #222222;
            font-weight: bold;
            text-align: center;
            box-sizing: border-box;
          }

          .form-box {
            :deep(.el-form-item) {
              margin: 0px !important;
              .el-form-item__label,
              .el-form-item__content {
                line-height: 36px;
              }
            }
            .form-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 18px 10px;
              & > div {
                width: 72px;
                height: 24px;
                line-height: 24px;
                border-radius: 50%;
                text-align: center;
                color: #fff;
                margin: 0 10px;
                cursor: pointer;
              }

              .search {
                background: #00a9b4;
                border-radius: 12px;
              }

              .reset {
                background: #3e97d1;
                border-radius: 12px;
              }
            }
          }
        }
        .right {
          flex: 1;
          .more-title {
            height: 35px;
            // line-height: 34px;
            padding: 6px 0 0 0;
            color: #222222;
            font-weight: bold;
            text-align: center;
            box-sizing: border-box;
          }
          :deep(.el-textarea__inner) {
            height: 100% !important;
          }

          .more-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 20px 10px;
            & > div {
              width: 72px;
              height: 24px;
              line-height: 24px;
              border-radius: 50%;
              text-align: center;
              color: #fff;
              margin: 0 10px;
              cursor: pointer;
            }

            .search {
              background: #00a9b4;
              border-radius: 12px;
            }

            .reset {
              background: #3e97d1;
              border-radius: 12px;
            }
          }
        }
      }
    }
  }
}
</style>
