<template>
  <div>
    <contact-detail-tow v-if="cutTemplates === 'themeTemplate'" :datas="contactData"></contact-detail-tow>
  </div>
</template>
<script>
import { GetContactInfo } from '@/api/home'
import mixins from '@/utils/mixins'
export default {
  name: 'contactPage',
  mixins: [mixins],

  data() {
    return {
      contactData: {}
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      let _this = this
      GetContactInfo().then(res => {
        if (res.success) {
          _this.contactData = res.data
        }
      })
    }
  }
}
</script>