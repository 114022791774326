/*
 * @Description: 项目文件路径
 * @Author: pcc
 * @Date: 2022-12-29 17:18:10
 * @LastEditors: zml
 * @LastEditTime: 2023-05-05 16:11:14
 */

/**
 * 蓝色风格
 * themeTemplate
 */
import contactDetailTow from "@/components/themeTemplate/page/detail/contact" // about
import aboutDetailTow from "@/components/themeTemplate/page/detail/index" // about
import pageCartTow from "@/components/themeTemplate/page/cart/index" // cart
import pageHomeTow from "@/components/themeTemplate/page/home" // home
import pageOrderDetailTow from "@/components/themeTemplate/page/order/detail" // order
import pageOrderTow from "@/components/themeTemplate/page/order/index" // order
import pageProDetailTow from "@/components/themeTemplate/page/product/proDetail.vue" //product
import pageProductTow from "@/components/themeTemplate/page/product/index.vue" //product
import pageNewsTow from "@/components/themeTemplate/page/news/index.vue" //news
import pageNewsDetailTow from "@/components/themeTemplate/page/news/newDetail.vue" //新闻详情

export default {
  components: {
    // 可丽星
    contactDetailTow,
    aboutDetailTow,
    pageCartTow,
    pageHomeTow,
    pageOrderDetailTow,
    pageOrderTow,
    pageProDetailTow,
    pageProductTow,
    pageNewsTow,
    pageNewsDetailTow
  },

  data() {
    return {
      cutTemplates: 'themeTemplate'
    }
  }
}
