<!--
 * @Description: 
 * @Author: zml
 * @Date: 2023-03-28 11:17:06
 * @LastEditors: zml
 * @LastEditTime: 2023-04-04 12:08:17
-->
<template>
  <div class="footer_box">
    <div class="container">
      <!-- 底部 end -->
      <div class="copyright">
        <div style="margin: 0 0 6px 0">{{ language == 'en' ? getSetting.enCopyRight : language == 'ru' ? getSetting.rusCopyRight : getSetting.copyRight }}</div>
        <div>{{$t('KL.Technic')}}：{{$t('KL.Software')}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'layoutFooterTwo',
  computed: {
    ...mapState('operate', ['language']),
    ...mapGetters('setting', {
      getSetting: 'get_setting'
    }),
    ...mapGetters('user', {
      userInfo: 'get_userInfo'
    })
  },
  methods: {
    gotoTop() {
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss" scoped>
.copyright{
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100px;
  padding: 10px 20px 10px;
  box-sizing: border-box;
}

</style>
