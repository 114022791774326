export default {
    login: '登录',
    registration: '注册',
    order: '我的订单',
    language: '中文',
    home: '首页',
    babyToys: '关键词',
    aboutus: '关于我们',
    product: '产品',
    cart: '购物车',
    contactus: '联系我们',
    comprehensiveInquiry: '综合查询',
    advancedSearch: '高级搜索',
    productName: '产品名称',
    priceRange: '价格区间',
    reset: '重置',
    search: '搜索',
    classify: '分类',
    categories: '产品分类',
    close: '收起',
    open: '展开',
    updateTime: '更新时间',
    startTime: '开始时间',
    endTime: '结束时间',
    confirm: '确认',
    minimumPrice: '最低价',
    highestPrice: '最高价',
    L: '长',
    W: '宽',
    H: '高',
    price: '价格',
    itemNo: '出厂货号',
    packing: '包装方式',
    productSize: '产品规格',
    packageSize: '包装规格',
    cartonSize: '外箱规格',
    OuterPacking: "外箱装量",
    CBMAndCUFT: '体积/材积',
    CUFT: '材积',
    CBM: '体积',
    GWAndNW: '毛重/净重',
    mOQ: '起订量',
    productInformation: '产品资料',
    relatedProducts: '相关产品',
    more: '更多',
    clear: '清空',
    inputPassword: '请输入密码',
    passwordLength: '密码长度不能小于6位',
    inputUserName: '请输入姓名',
    inputUserAccount: '请输入用户名',
    inputVaildeCode: '请输入验证码',
    inputMobile: '请输入手机号',
    inputEmail: '请输入邮箱',
    inputEmailRules: '邮箱格式不正确',
    password: '密码',
    username: '姓名',
    vaildecode: '验证码',
    gender: '性别',
    useraccount: '用户名',
    man: '男',
    woman: '女',
    mobile: '手机号',
    email: '邮箱',
    address: '联系地址',
    CodeError: '验证码错误',
    resUserError: '用户名已存在',
    notreviewed: '账号未审核',
    loginError: '账号或密码输入错误',
    loginSuccess: '登陆成功',
    regSuccess: '注册成功',
    deleteSuccess: '删除成功',
    toregister: '还没有账号？去注册',
    toLogin: '已经有账号？去登陆',
    logout: '退出',
    myShoppingCart: '我的购物车',
    ID: '序号',
    picture: '图片',
    ItemNO: '编号',
    name: '名称',
    total: '合计',
    delete: '删除',
    CTNS: '箱数',
    checkAll: '全选',
    checkSelect: '本页选中一键加购',
    page: '页',
    goTO: '前往',
    totalRecords: '总款数',
    totalCTNS: '总箱量',
    totalQuantity: '总数量',
    totalPrice: '总价',
    submit: '提交',
    orderDetails: '订单详情',
    OrderInformation: '订单信息',
    number: '单号',
    orderTime: '订单时间',
    linkman: '联系人',
    remark: '备注',
    viewDetails: '查看详情',
    companyContact: '联系人',
    companyTel: '电话',
    companyFax: '传真',
    companyMail: '邮箱',
    companyAddress: '地址',
    productCenter: '产品中心',
    helpCenter: '帮助中心',
    member: '会员中心',
    productdemand: '产品需求',
    newproduct: '最新产品',
    registerhelp: '注册帮助',
    orderhelp: '订单帮助',
    newproducts: '新品展示',
    highRec: '推荐产品',
    viewmore: '查看更多',
    videocenter: '视频中心',
    searchPicture: '图搜',
    choosePicture: '选择图片',
    clickOrDrag: '点击/拖拽文件到此处',
    pictureCrop: '图片裁剪区域',
    cancel: '取消',
    confirm: '确定',
    pleaseLogin: '请登录',
    addCart: "添加购物车",
    delCart: "取消加购",
    viewPrice: '查看价格',
    noData: '暂无数据',
    systemTip: '系统提示',
    deleteData: '是否删除该条数据？',
    clearCartData: '是否清空全部购物车？',
    orderSuccess: '订单创建成功！',
    submitTip: '确认提交订单？',
    noLogin: '添加购物车前，请登陆',
    notImage: '粘贴内容不是图片',
    noSelect: '请选择数据',
    hotSaleProduct: '热销产品',
    popularRecommendation: '热门推荐',
    please_contact_us: '如果您有任何疑问，请随时传真或打电话',
    ock_email: '邮箱',
    contactUs: '联系我们',
    hotLine: '热线电话',
    companyAddres: '公司地址',
    workingHours: '办公时间',
    week: '周一到周五',
    companyInfo: '公司简介',
    noName: '暂无翻译',
    serviceHotine: "24/7 热线服务",
    success: "添加成功",
    del: "删除成功",
    orderStatus: '订单状态',
    sendDate: '发货日期',
    expressNumber: '快递单号',
    unshipped: '未发货',
    shipped: '已发货',

    Disclaimer: "免责声明:本网站所有图片和产品均来自厂商，版权归厂商所有。",
    Technic: '技术支持',
    Software: '深圳宏升软件技术开发有限公司',
    coNuNum: "公司编号",
    all: '全部分类',
    userAddress: '收货地址',
    consignee: '收货人',
    addressDetail: '详细地址',
    addressinformation: '收货地址信息',
    defaultAddress: '默认',
    defaultSettings: '设置默认',
    edit: '编辑地址',
    add: '新增地址',
    AddinganAddress: '新增地址',
    Edittheaddress: '编辑地址',
    Ok: '保存',
    Address_Consignee: '请输入收货人',
    Address_Mobile: '请输入手机号',
    Address_Address: '请输入地址',
    Video_playback: '视频播放',

    "KL.Nav.home": "首页",
    "KL.Nav.AboutUs": "公司简介",
    "KL.Nav.Product": "产品展示",
    "KL.Nav.ContactUs": "联系我们",
    "KL.Nav.Cart": "购物车",
    "KL.CardTit.ContactUs": "联系我们",
    "KL.CardTit.latestProduct": "最新产品",
    "KL.CardTit.MemberArea": "会员专区",
    "KL.CardTit.Category": "产品分类",
    "KL.CardTit.HotProduct": "热销产品",
    "KL.CardTit.AdvancedSearch": "高级搜索",
    "KL.CardTit.AboutUs": "公司简介",
    "KL.CardTit.productDetails": "产品详情",
    "KL.fax": "传真",
    "KL.Mob&WeChat": "手机 & 微信",
    "KL.Email": "邮箱",
    "KL.More": "更多",
    "KL.placeholderProductKey": "快速搜索",
    "KL.MemberNumber": "会员号",
    "KL.pleaseMemberNumber": "请输入会员号",
    "KL.Password": "密码",
    "KL.PleaseEnterPassword": "请输入密码",
    "KL.Login": "登录",
    "KL.Register": "注册",
    "KL.addCart": "加入购物车",
    "KL.Hello": "您好",
    "KL.MyOrder": "我的订单",
    "KL.logOut": "退出登录",
    "KL.SearchByDescription": "按描述搜索",
    "KL.Date": "日期",
    "KL.to": "到",
    "KL.describe": "描述",
    "KL.kind": "种类",
    "KL.price": "价格",
    "KL.size": "尺寸",
    "KL.L": "长",
    "KL.W": "宽",
    "KL.H": "高",
    "KL.Orders": "顺序",
    "KL.MultipleSearch": "多货号搜索",
    "KL.Search": "搜索",
    "KL.Reset": "重置",
    "KL.MultipleSearchText": "*举例：请输入完整编号HS000001 以回车区分",
    "KL.PleaseSelect": "请选择",
    "KL.PleaseLoginBeforeAdding": "请登录后再添加",
    "KL.AddSuccess": "添加成功",
    "KL.AddError": "添加失败",
    "KL.PleaseEnterSearchCriteria": "请输入搜索条件",
    "KL.LoginSuccess": "登录成功",
    "KL.LoginError": "登录失败",
    "KL.All": "全部",
    "KL.Product": "产品展示",
    "KL.isLogOut": "是否继续退出登录?",
    "KL.SystemPrompt": "系统提示",
    "KL.Confirm": "确定",
    "KL.Cancel": "取消",
    "KL.OuterQTY": "外箱装量",
    "KL.prodcutPrice": "产品价格",
    "KL.innerBoxes": "内盒个数",
    "KL.CartonSize": "外箱尺寸",
    "KL.PageUp": "上一页",
    "KL.PageDown": "下一页",
    "KL.LoginToSeePrices": "登录查看价格",
    "KL.Software": "宏升软件",
    "KL.Technic": '技术支持',
    "KL.PleaseLoginFirst": '请先登录',
    "KL.NoMore": '没有更多了',
    "KL.remark": '备注',
    "KL.mobile": '手机',
    "KL.Wechat": '微信',
    "KL.news": '新闻动态',
    "KL.newsDetail": '新闻详情',
    "KL.releaseTime": '发布时间',
    "KL.attestation": '产品认证'
}
