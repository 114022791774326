import request from '@/utils/request'

// 获取验证码
export function GetValideCode (data) {
  return request({
    url: 'front/MemberWeb/GetValideCode',
    method: 'post',
    data
  })
}

// 登录账号
export function LoginMember (data) {
  return request({
    url: 'front/MemberWeb/LoginMember',
    method: 'post',
    data
  })
}

// 注册账号
export function RegisterMember (data) {
  return request({
    url: 'front/MemberWeb/RegisterMember',
    method: 'post',
    data
  })
}


// 网站用户登录（邮箱无密码登录）
export function LoginMemberByEmail (data) {
  return request({
    url: 'front/MemberWeb/LoginMemberByEmail',
    method: 'post',
    data
  })
}

// 网站用户登录（无验证码）
export function LoginMemberNoCode (data) {
  return request({
    url: 'front/MemberWeb/LoginMemberNoCode',
    method: 'post',
    data
  })
}